import Footer from './components/website/Footer.jsx';
import Header from './components/Header.jsx';
import Navigation from './components/Navigation';
import Dashboard from './views/Dashboard.jsx';
import OAuth from './views/OAuth.jsx';
import Contact from './views/website/Contact.jsx'
import Customers from './views/Customers.jsx';
import Customer from './views/Customer.jsx';
import Earnings from './views/Earnings.jsx';
import VIP from './views/VIP.jsx';
import Promotions from './views/promotions/Promotions.jsx';
import UpdatePromotion from './views/promotions/UpdatePromotion.jsx';
import PromotionReporting from './views/promotions/PromotionReporting.jsx';
import Giveaways from './views/giveaways/Giveaways.jsx';
import UpdateGiveaway from './views/giveaways/UpdateGiveaway.jsx';
import GiveawaysReporting from './views/giveaways/GiveawaysReporting.jsx';
import Tracking from './views/Tracking.jsx';
import Integrations from './views/Integrations.jsx';
import Settings from './views/Settings.jsx';
import SignUp from './views/website/SignUp.jsx';
import Login from './views/website/Login.jsx';
import Error from './views/Error.jsx';
import { ResponseDisplay } from './components/utility/Utility.jsx';
import { AuthContextProvider } from './contexts/AuthContext';
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import AuthorizedRoute from './components/AuthorizedRoute.jsx';
import UpdateIntegration from './views/UpdateIntegration.jsx';
import Home from './views/website/NewHome.jsx';
import UpdatePassword from './views/website/UpdatePassword.jsx';
import Earn from './views/website/Earn.jsx';
import WebGiveaways from './views/website/Giveaways.jsx';
import WebPromotions from './views/website/Promotions.jsx';
import Pricing from './views/website/Pricing.jsx';
import PrivacyPolicy from './views/website/PrivacyPolicy.jsx';
import Redeem from './views/website/Redeem.jsx';
import WVIP from './views/website/WVIP.jsx';



export default function App() {
  const location = useLocation();
  const [id, setId] = useState('body');
  useEffect(() => {
    setId(location.pathname.includes('/app') ? "body" : "website")
  }, [location]);
  
  return (
    <AuthContextProvider>
      <div className="App">
        <Header />
        <div id={id}>
          <Navigation />
          <Routes>
            <Route path="/auth/shopify" element={<OAuth />} />
            <Route path="/app/dashboard" element={<AuthorizedRoute><Dashboard /></AuthorizedRoute>} />
            <Route path="/app/customers" element={<AuthorizedRoute><Customers /></AuthorizedRoute>} />
            <Route path="/app/customers/:id" element={<AuthorizedRoute><Customer /></AuthorizedRoute>} />
            <Route path="/app/program" element={<AuthorizedRoute><Earnings /></AuthorizedRoute>} />
            <Route path="/app/promotions" element={<AuthorizedRoute><Promotions /></AuthorizedRoute>} />
            <Route path="/app/promotions/:id" element={<AuthorizedRoute><UpdatePromotion /></AuthorizedRoute>} />
            <Route path="/app/promotions/:id/amplifiers" element={<AuthorizedRoute><UpdatePromotion /></AuthorizedRoute>} />
            <Route path="/app/promotions/:id/review" element={<AuthorizedRoute><UpdatePromotion /></AuthorizedRoute>} />
            <Route path="/app/promotions/:id/reporting" element={<AuthorizedRoute><PromotionReporting /></AuthorizedRoute>} />
            <Route path="/app/giveaways" element={<AuthorizedRoute><Giveaways /></AuthorizedRoute>} />
            <Route path="/app/giveaways/:id" element={<AuthorizedRoute><UpdateGiveaway /></AuthorizedRoute>} />
            <Route path="/app/giveaways/:id/rewards" element={<AuthorizedRoute><UpdateGiveaway /></AuthorizedRoute>} />
            <Route path="/app/giveaways/:id/review" element={<AuthorizedRoute><UpdateGiveaway /></AuthorizedRoute>} />
            <Route path="/app/giveaways/:id/reporting" element={<AuthorizedRoute><GiveawaysReporting /></AuthorizedRoute>} />
            <Route path="/app/integrations" element={<AuthorizedRoute><Integrations /></AuthorizedRoute>} />
            <Route path="/app/integrations/:id" element={<AuthorizedRoute><UpdateIntegration /></AuthorizedRoute>} />
            <Route path="/app/tracking" element={<AuthorizedRoute><Tracking /></AuthorizedRoute>} />
            <Route path="/app/settings" element={<AuthorizedRoute><Settings /></AuthorizedRoute>} />
            <Route path="/app/vip" element={<AuthorizedRoute><VIP /></AuthorizedRoute>} />
            {/* Website routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/reset-password" element={<UpdatePassword />} />
            <Route path="/features/earn" element={<Earn />} />
            <Route path="/features/redeem" element={<Redeem />} />
            <Route path="/features/vip" element={<WVIP />} />
            <Route path="/features/giveaways" element={<WebGiveaways />} />
            <Route path="/features/promotions" element={<WebPromotions />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Error />} />
            {/* Duplicate this for /app/* */}
          </Routes>
          <script
          type="text/javascript"
          src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=V4YTdC"
          ></script>
        </div>
        <ResponseDisplay />
        <Footer />
      </div>

    </AuthContextProvider>
  )
  
}