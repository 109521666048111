import React, { useState, useEffect, useContext } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
import { Loading } from '../../components/utility/Utility';

export default function SignUp() {
  const navigate = useNavigate();
  const { authed, signup, getTkn } = useContext(AuthContext);
  const [csrfToken, setCsrfToken] = useState('');
  const [formErrors, setFormErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (authed) {
      navigate('/app/dashboard');
    } else {
      getTkn('/app/auth/signup', setCsrfToken);
    }
  }, [authed]);

  useEffect(() => {
    let toDisable = [...document.querySelectorAll('.signup__form input')];
    toDisable.forEach(inp => inp.disabled = submitting);
  }, [submitting])
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=6LcZ-UAqAAAAAMao0jmw3OyJhmXggpiMO2fydLl3`;
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <div className="signup">
      <h1 className='signup__header'>Sign Up</h1>
      <hr className="signup__horizontal-rule" />
      <Formik
        initialValues={{ email: '', password: '', storeName: '', storeUrl: '', csrf: csrfToken }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address').required('Field is required.'),
          password: Yup.string()
            .required('Field is required.')
            .min(8, 'Password must be at least 8 characters')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
            .matches(/\d/, 'Password must contain at least one number.')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
          storeName: Yup.string()
            .required('Please enter the name of your company.'),
          storeUrl: Yup.string()
            .matches(
              /^(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i,
              'Enter a valid URL'
            )
            .required('Please enter website URL.'),
        })}
        onSubmit={(values, { setFieldValue }) => {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcZ-UAqAAAAAMao0jmw3OyJhmXggpiMO2fydLl3', { action: 'submit' }).then(async (token) => {
              const formData = {
                ...values,
                recaptchaToken: token,
              };
              setSubmitting(true);
              signup(formData)
                .then(() => navigate('/app/dashboard'))
                .catch(err => {
                  if (err.response && err.response.status === 401) {
                    setFormErrors('New token needed, refresh the page.');
                  } else {
                    setFormErrors(err.response.data);
                    getTkn('/app/auth/signup', setFieldValue, 'csrf');
                  }
                })
                .finally(() => setSubmitting(false));
            });
          });  
        }}
        enableReinitialize={true} // Allow Formik to update when csrfToken changes
      >
        <Form id="sign-up-form" className="auth-form signup__form">
          <Field name="csrf" type="hidden" />
          <label className="signup__form-label" htmlFor="email">Email</label>
          <Field name="email" type="email" placeholder="customer@gmail.com" />
          <ErrorMessage component="div" className="form-error" name="email" />

          <label className="signup__form-label" htmlFor="password">Password</label>
          <Field name="password" type="password" />
          <ErrorMessage component="div" className="form-error" name="password" />

          <label className="signup__form-label" htmlFor="storeName">Company name</label>
          <Field name="storeName" />
          <ErrorMessage component="div" className="form-error" name="storeName" />

          <label className="signup__form-label" htmlFor="storeUrl">Company website</label>
          <Field name="storeUrl" />
          <ErrorMessage component="div" className="form-error" name="storeUrl" />

          {formErrors && <div className="form-errors">{formErrors}</div>}
          {submitting && <Loading />}
          {!submitting &&
            <>
              <a className="login-form__text" href="/login">Already have an account? Login.</a>
              <button type="submit" className="gradient">Create account</button>
            </>
          }
        </Form>
      </Formik>
    </div>
  );
}
