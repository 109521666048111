import { GiveawayRewards, GiveawayHero, GiveawaySegments, GiveawayReview } from "../../components/WebsiteComponents"
import '../../assets/css/website/component-giveaway-hero.css'
import '../../assets/css/website/component-giveaway-rewards.css'
import '../../assets/css/website/component-giveaway-segments.css'
import '../../assets/css/website/component-giveaway-review.css'
import { CallToAction } from "../../components/website/Utility"

export default function WebGiveaways() {
  return (
    <>
      <GiveawayHero 
        heading="GIVEAWAYS" 
        img="https://amplify-loyalty-website.s3.amazonaws.com/1-Chips+(1).png"
        description="Define groups of customers to run a giveaway of coupons and points based on how they have interacted with your store."
      />
      <GiveawaySegments 
        img="https://amplify-loyalty-website.s3.amazonaws.com/Giveaway+Segments.png"
        heading="SEGMENT CUSTOMERS BASED ON PAST INTERACTIONS"
        body="First choose what customers you will issue rewards to based on how they have interacted with your store. This can include how often or how much money they have spent over a previous period of time or something as simple as if they have actually created an account."
      />
      <GiveawayRewards 
        heading="CREATE COUPON OR POINT REWARDS"
        body="Based on your chosen customer segment, choose how many of them will receive a specific number of points or even a custom coupon of your choosing with all of the restrictions available of a regular Shopify coupon."
        tImg="https://amplify-loyalty-website.s3.amazonaws.com/Giveaways+Stats.png"
        bImgOne="https://amplify-loyalty-website.s3.amazonaws.com/Giveaway+Coupon.png"
        bImgTwo="https://amplify-loyalty-website.s3.amazonaws.com/Giveaway+Points.png"
      />
      <GiveawayReview 
        heading="SCHEDULE OR SEND"
        body="Review your Giveaway settings to send now or schedule for later."
        img="https://amplify-loyalty-website.s3.amazonaws.com/Giveaway+Review.png"
      />
      <CallToAction />
    </>
  )
}