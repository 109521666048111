// import ellipsis from '../assets/images/icons/ellipsis-veritcal-solid.svg';
import { Formik, Field, Form, ErrorMessage, useFormikContext, isSubmitting, getIn } from 'formik';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import {
  DatePicker, 
  TimePicker, 
  ColorPicker, 
  CreateText, 
  ProductCard, 
  titleCaseText, 
  SearchPreview, 
  PicksDisplay, 
  WarningIcon, 
  Loading,
  EditMenu,
  DropDownFilter,
  TablePagination,
  ToolTip,
  SimpleSearchPreview,
  toUserTimezone,
  fromUserTimezone
} from './utility/Utility';
import axios from 'axios';
import icon from '../assets/images/amplify-loyalty-icon.png';

export const PromotionsTable = (props) => {
  const {logout} = useContext(AuthContext);
  const {promotions, setPromotions, pageInfo, setPageInfo} = props;
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const moneyFormat = Intl.NumberFormat('en-US', {style: "currency", currency: 'USD', maximumFractionDigits: 0});
  const numberFormat = Intl.NumberFormat('en-US');

  const disableEditing = async () => {
    const buttons = [
      ...document.querySelectorAll('.promotions-table-filters__input'),
      ...document.querySelectorAll('.promotions-header__new-promotion')
    ];
    for await (let btn of buttons) {
      btn.disabled = !disabled;
    }
    setDisabled(!disabled);
  }

  const createNewPromotion = () => {
    const button = document.querySelector('.promotions-header__new-promotion');
    button.disabled = true;
    let today = new Date();
    let arbitraryEnd = new Date();
    arbitraryEnd.setDate(arbitraryEnd.getDate() + 7);
    let todayDate = `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`;
    let todayTime = `${today.getHours() > 12 ? (today.getHours() - 12 < 10 ? `0${today.getHours() - 12}` : today.getHours() - 12) : today.getHours() === 0 ? '12' : today.getHours()}:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}:${today.getSeconds() > 9 ? today.getSeconds() : `0${today.getSeconds()}`}`

    axios.post(
      'https://api.amplifyloyalty.com/app/stores/promotions',
      {
        name: `New Promotion - ${todayDate} ${todayTime}`,
        multiplier: 2,
        start_time: new Date(`${todayDate} 00:00:00`),
        end_time: new Date(`${arbitraryEnd.getMonth()+1}/${arbitraryEnd.getDate()}/${arbitraryEnd.getFullYear()} 00:00:00`)
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        setTimeout(() => {
          navigate(`/app/promotions/${res.data._id}`);
        }, 1000)
      }
    }).catch(err => {
      console.log(err)
      button.disabled = false;
      // responseHandler(err);
    })
  }

  const duplicatePromotion = (promotion) => {
    const button = document.querySelector('.promotions-header__new-promotion');
    button.disabled = true;
    let today = new Date();
    let arbitraryEnd = new Date();
    arbitraryEnd.setDate(arbitraryEnd.getDate() + 7);
    let todayDate = `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`;

    axios.post(
      'https://api.amplifyloyalty.com/app/stores/promotions/duplicate',
      {
        _id: promotion._id,
        name: `${promotion.name} - Copy`,
        start_time: new Date(`${todayDate} 00:00:00`),
        end_time: new Date(`${arbitraryEnd.getMonth()+1}/${arbitraryEnd.getDate()}/${arbitraryEnd.getFullYear()} 00:00:00`),
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log(res);
        setTimeout(() => {
          navigate(`/app/promotions/${res.data._id}`);
        }, 1000);
      }
    }).catch(err => {
      console.log(err);
      button.disabled = false;
      // responseHandler(err);
    })
  }

  const deletePromotion = (promotion) => {
    if (window.confirm('Are you sure you want to delete this Promotion? It cannot be undone.')) {
      axios.post(
        'https://api.amplifyloyalty.com/app/stores/promotions/delete',
        promotion,
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          console.log(res);
          navigate(0);
          // setpromotions without the promotion
        }
      }).catch(err => {
        console.log(err);
        // responsehandler
      })
    }
  }
  
  const pauseAndEdit = (promotion) => {
    disableEditing();
    // disable all buttons/inputs 

    axios.post('https://api.amplifyloyalty.com/app/stores/promotions/pause',
      promotion,
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        navigate(`/app/promotions/${promotion._id}`);
      }
    }).catch(err => {
      console.log(err);
      disableEditing();
      // responsehandler
    })
  }

  return (
    <section className='promotions-container centralize'>
      <div className="promotions-header">
        <p>Create and schedule promotions to go live on your store.</p>
        <button className="promotions-header__new-promotion btn gradient" onClick={() => createNewPromotion()}>NEW PROMOTION</button>
      </div>
      <div className="promotions-table">
        <TableFilters promotions={promotions} setPromotions={setPromotions} pageInfo={pageInfo} setPageInfo={setPageInfo} disabled={disabled}>
          <div className="promotions-table__header">
            <div className="promotions-table__heading">Promotion</div>
            <div className="promotions-table__metrics">
              <div className="promotions-table__heading">Points Issued</div>
              <div className="promotions-table__heading">Orders</div>
              <div className="promotions-table__heading">Sales</div>
              <div className="promotions-table__heading"></div>
            </div>
          </div>
          <div className="promotions-table__body">
            {!promotions && <Loading className="promotions-table__loading" />}  
            {promotions.length === 0 && 
              <div className="promotions-table__empty-warning">No Promotions found.</div>
            }
            {promotions.length > 0 &&
              promotions.map((promo, dataKey) => {
                let start = new Date(promo.start_time);
                let end = new Date(promo.end_time);
                return (
                  <div key={dataKey} className="promotions-table__row">
                    <div className="promotions-table__cell promotions-table__info">
                      <div className="promotions-table__title">
                        <a className="promotions-table__name" href={`/app/promotions/${promo._id}${promo.status !== 'draft' ? '/reporting' : ''}`}>{promo.name}</a>
                        {promo.amplifiers ? Object.keys(promo.amplifiers).map(key => {
                          let icons = {
                            'product': 'shirt',
                            'announcement': 'bullhorn',
                            'collection': 'bars',
                            'cart': 'cart-shopping'
                          }
                          if (promo.amplifiers[key].status) {
                            return (
                              <i key={key} className={`fa-solid fa-${icons[key]} promotions-table__amplifier-icon`}></i>
                            )
                          }
                        }) : null}
                      </div>
                      <div className="promotions-table__execution-info">
                        {/* put the status here (scheduled, executed) */}
                        <span className="promotions-table__status">{titleCaseText(promo.status)}:</span> {`${start.getMonth()+1}/${start.getDate()}/${start.getFullYear().toString().slice(2,)} `} 
                        at {`${start.getHours() > 12 ? start.getHours() % 12 : start.getHours() === 0 ? 12 : start.getHours()}:${start.getMinutes() < 10 ? `0${start.getMinutes()}` : start.getMinutes()}${start.getHours() > 11 ? 'PM' : 'AM'} `}
                        until {`${end.getMonth()+1}/${end.getDate()}/${end.getFullYear().toString().slice(2,)} `} 
                        at {`${end.getHours() > 12 ? end.getHours() % 12 : end.getHours() === 0 ? 12 : end.getHours()}:${end.getMinutes() < 10 ? `0${end.getMinutes()}` : end.getMinutes()}${end.getHours() > 11 ? 'PM' : 'AM'}`}
                      </div>
                    </div>
                    <div className="promotions-table__metrics">
                      <div className="promotions-table__cell">{numberFormat.format(promo.points)}</div>
                      <div className="promotions-table__cell">{numberFormat.format(promo.orders)}</div>
                      <div className="promotions-table__cell">{moneyFormat.format(promo.subtotal)}</div>
                      <div className="promotions-table__cell">
                        <EditMenu 
                          className="promotions-table__edit" 
                          disabled={disabled}
                          options={[
                            {
                              text: promo.status === 'ended' || promo.status === 'active' ? 
                                'Analytics' : 
                                promo.status === 'scheduled' ? 
                                  'Pause & Edit' :
                                  'Edit',
                              action: promo.status === 'ended' || promo.status === 'active' ? 
                                () => navigate(`/app/promotions/${promo._id}/reporting`) : 
                                promo.status === 'scheduled' ? 
                                  () => pauseAndEdit(promo) : 
                                  () => navigate(`/app/promotions/${promo._id}`)
                            },
                            {
                              text: 'Duplicate',
                              action: () => duplicatePromotion(promo)
                            },
                            {
                              text: 'Delete',
                              action: () => deletePromotion(promo),
                              disabled: promo.status === 'draft' ? false : true
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </TableFilters>
      </div>
    </section>
  )
}

const TableFilters = (props) => {
  const {logout} = useContext(AuthContext);
  const {promotions, setPromotions, pageInfo, setPageInfo, disabled} = props;
  const [activeDate, setActiveDate] = useState('All Time');
  const [activeSort, setActiveSort] = useState('End Time');
  const [activeDirection, setActiveDirection] = useState(-1);
  const [activeStatus, setActiveStatus] = useState('Any Status');
  const prevSearch = useRef('');
  const prevPromotions = useRef(promotions);

  const getData = (range, start, end, sort, direction, page, status, search) => {
    console.log({start, end})
    setPromotions(false);
    let newSort;
    if (sort.includes(' ')) {
      newSort = sort.toLowerCase().split(' ').join('_'); 
    }
    axios.get('https://api.amplifyloyalty.com/app/stores/promotions',
      { 
        params: {
          jwt: localStorage.getItem('_id'),
          range: range,
          start_date: start,
          end_date: end,
          sort: newSort || sort,
          direction: direction,
          page: page || 1,
          status: status === 'Any Status' ? null : status,
          search: search || document.querySelector('.promotions-table-filters__input').value
        }
      }).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          let info = {...res.data};
          delete info.data;
          setPageInfo(info);
          setPromotions(res.data.data || []);
        }
      }).catch(err => {
        console.log(err);
      });
  }

  const search = (value) => {
    prevPromotions.current = promotions;
    setTimeout(() => {
      let currentValue = document.querySelector('.promotions-table-filters__input').value;
      if (currentValue === value && value !== prevSearch.current) {
        let [start, end] = checkForRange();
        getData(activeDate, start, end, activeSort, activeDirection, null, activeStatus, value);
        prevSearch.current = value;
      } else {
        setPromotions(prevPromotions.current);
      }
    }, 1000)
  }

  const checkForRange = () => {
    let dates = document.querySelectorAll('.date-range-selector__input');
    console.log(dates);
    if (dates.length === 2) {
      return [...dates].map(input => input.value);
    }
  }

  return (
    <>
      <div className="promotions-table-filters">
        <div className="promotions-table-filters__filter-wrapper">
          <input type="text" className="promotions-table-filters__input" placeholder="Search promotions" onChange={(e) => search(e.target.value)}/>
          <DropDownFilter 
            className="promotions-table-filters__status-filter"
            active={activeStatus}
            setActive={setActiveStatus}
            disabled={disabled}
            options={[
              {
                text: 'Any Status',
                action: () => {
                  let [start, end] = checkForRange();
                  getData(activeDate, start, end, activeSort, activeDirection, null, 'Any Status');
                }
              },
              {
                text: 'Active',
                action: () => {
                  let [start, end] = checkForRange();
                  getData(activeDate, start, end, activeSort, activeDirection, null, 'active');
                }
              },
              {
                text: 'Scheduled',
                action: () => {
                  let [start, end] = checkForRange();
                  getData(activeDate, start, end, activeSort, activeDirection, null, 'scheduled');
                }
              },
              {
                text: 'Ended',
                action: () => {
                  let [start, end] = checkForRange();
                  getData(activeDate, start, end, activeSort, activeDirection, null, 'ended');
                }
              },
              {
                text: 'Draft',
                action: () => {
                  let [start, end] = checkForRange();
                  getData(activeDate, start, end, activeSort, activeDirection, null, 'draft');
                }
              }
            ]}
          />
        </div>
        <div className="promotions-table-filters__modifiers">
          <div className="promotions-table-filters__filter-wrapper">
            <label className="promotions-table-filters__sort-label" htmlFor="sort">Sort by</label>
            <DropDownFilter 
              className="promotions-table-filters__date-filter"
              active={activeSort}
              setActive={setActiveSort}
              disabled={disabled}
              options={[
                {
                  text: 'End Time',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'end_time', activeDirection, null, activeStatus);
                  }
                },
                {
                  text: 'Start Time',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'start_time', activeDirection, null, activeStatus);
                  }
                }
              ]}
            />
            <DropDownFilter 
              className="promotions-table-filters__sort-direction"
              active={activeDirection}
              setActive={setActiveDirection}
              disabled={disabled}
              options={[
                {
                  text: -1,
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, activeSort, -1, null, activeStatus);
                  }
                },
                {
                  text: 1,
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, activeSort, 1, null, activeStatus);
                  }
                }
              ]}
            />
          </div>
          <DropDownFilter 
            className="promotions-table-filters__filter-wrapper"
            active={activeDate}
            setActive={setActiveDate}
            disabled={disabled}
            options={[
              {
                text: 'All Time',
                action: () => getData('All Time', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last 90 Days',
                action: () => getData('Last 90 Days', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last 180 Days',
                action: () => getData('Last 180 Days', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'This Year',
                action: () => getData('This Year', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last Year',
                action: () => getData('Last Year', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Custom Range',
                action: (start, end) => getData('Custom Range', start, end, activeSort, activeDirection, null, activeStatus)
              }
            ]}
            dates={true}
          />
        </div>
      </div>
      {props.children}
      {pageInfo.totalPages > 1 && 
        <TablePagination 
          options={pageInfo} 
          docType="promotions"
          next={
            () => {
              let [start, end] = checkForRange();
              getData(activeDate, start, end, activeSort, activeDirection, pageInfo.nextPage, activeStatus);
            }
          }
          previous={
            () => {
              let [start, end] = checkForRange();
              getData(activeDate, start, end, activeSort, activeDirection, pageInfo.prevPage, activeStatus);
            }
          }
        />
      }
    </>
  )
}

export const PromotionsHeader = (props) => {
  const {dynamicName, promotion} = props;
  const location = useLocation();
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    const links = document.querySelectorAll('.update-promotion__breadcrumb');
    links.forEach(link => link.addEventListener('click', (e) => {
      if (!e.currentTarget.classList.contains('update-promotion__breadcrumb--past')) e.preventDefault();
    }))
  }, [])

  return (
    <section className="update-promotion__header block-section centralize">
      {/* Make this overflow hidden and set to a maxwidth then add the ... */}
        <h1 className="update-promotion__heading">{`Promotions > ${dynamicName.length > 35 ? `${dynamicName.slice(0,35)}...` : dynamicName}`}</h1>
      <div className="update-promotion__breadcrumbs">
        <a href={`/app/promotions/${id}`} className={`update-promotion__breadcrumb${!location.pathname.includes('amplifiers') && !location.pathname.includes('review') ? ' update-promotion__breadcrumb--active' :  ' update-promotion__breadcrumb--past'}`}>
          <div className={`update-promotion__breadcrumb-number ${!location.pathname.includes('amplifiers') && !location.pathname.includes('review') ? ' update-promotion__breadcrumb-number--active' : ''}`}>
            1
          </div>
          <div className="update-promotion__breadcrumb-title">
            INFO
          </div>
        </a>
        <div className="update-promotion__breadcrumb-trail"></div>
        <a href={`/app/promotions/${id}/amplifiers`} className={`update-promotion__breadcrumb${location.pathname.includes('amplifiers') ? ' update-promotion__breadcrumb--active' : location.pathname.includes('review') ? ' update-promotion__breadcrumb--past' : ''}`}>
          <div className={`update-promotion__breadcrumb-number ${location.pathname.includes('amplifiers') ? ' update-promotion__breadcrumb-number--active' : ''}`}>
            2
          </div>
          <div className="update-promotion__breadcrumb-title">
            AMPLIFIERS
          </div>
        </a>
        <div className="update-promotion__breadcrumb-trail"></div>
        <div className={`update-promotion__breadcrumb${location.pathname.includes('review') ? ' update-promotion__breadcrumb--active' : ''}`}>
          <div className={`update-promotion__breadcrumb-number ${location.pathname.includes('review') ? ' update-promotion__breadcrumb-number--active' : ''}`}>
            3
          </div>
          <div className="update-promotion__breadcrumb-title">
            REVIEW
          </div>
        </div>
      </div>
    </section>
  )
}

export const NewPromotionsInfo = (props) => {
  const {logout} = useContext(AuthContext);
  const {promotion, setDynamicName, conflictingPromotions} = props;
  let today = new Date();
  let arbitraryEnd = new Date();
  arbitraryEnd.setDate(arbitraryEnd.getDate() + 7);
  let start = new Date(promotion.start_time) < today ? toUserTimezone(today) : toUserTimezone(new Date(promotion.start_time));
  let end = new Date(promotion.end_time) < today ? toUserTimezone(arbitraryEnd) : toUserTimezone(new Date(promotion.end_time));
  const [values, setValues] = useState({
    name: promotion.name,
    multiplier: promotion.multiplier,
    start_date: `${start.getMonth()+1}/${start.getDate()}/${start.getFullYear()}`,
    start_hour: `${start.getHours() > 12 ? start.getHours() - 12 : start.getHours() === 0 ? '12' : start.getHours()}`,
    start_min: `${start.getMinutes() < 10 ? `0${start.getMinutes()}` : start.getMinutes()}`,
    start_daytime: `${start.getHours() > 11 ? 'pm' : 'am'}`,
    end_date: `${end.getMonth()+1}/${end.getDate()}/${end.getFullYear()}`,
    end_hour: `${end.getHours() > 12 ? end.getHours() - 12 : end.getHours() === 0 ? '12' : end.getHours()}`,
    end_min: `${end.getMinutes() < 10 ? `0${end.getMinutes()}` : end.getMinutes()}`,
    end_daytime: `${end.getHours() > 11 ? 'pm' : 'am'}`,
    restriction_type: promotion.restriction_type,
  });
  const [restriction, setRestriction] = useState(false);
  const [searchPreview, setSearchPreview] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [collectionIds, setCollectionIds] = useState([]);
  const [error, setError] = useState(false);
  const {id} = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (promotion.restriction_type === 'products') {
      setProductIds(promotion.ids);
    }
    if (promotion.restriction_type === 'collections') {
      setCollectionIds(promotion.ids);
    }
  }, [])

  const updateValue = (name, value) => {
    setValues({...values, [name]: value});
  }

  const dateValidation = async (values) => {
    let isError = false;
    let startCompare = new Date(
      values['start_date'].split('/')[2], 
      values['start_date'].split('/')[0] - 1, 
      values['start_date'].split('/')[1], 
      values['start_daytime'] === 'am' ? (values['start_hour'] === '12' ? 0 : parseInt(values['start_hour'])) :
      (values['start_hour'] === '12' ? 12 : parseInt(values['start_hour']) + 12),
      parseInt(values['start_min'])
    );

    let endCompare = new Date(
      values['end_date'].split('/')[2], 
      values['end_date'].split('/')[0] - 1, 
      values['end_date'].split('/')[1], 
      values['end_daytime'] === 'am' ? (values['end_hour'] === '12' ? 0 : parseInt(values['end_hour'])) :
      (values['end_hour'] === '12' ? 12 : parseInt(values['end_hour']) + 12),
      parseInt(values['end_min'])
    );

    if (endCompare < startCompare) {
      setError({message: "End date can't be before start date.", type: 'end_date'});
      return false; 
    }

    const [startDate, endDate] = [{
      dateValues: values.start_date.split('/'), 
      daytime: values.start_daytime, 
      hour: values.start_hour, 
      min: values.start_min, 
      type: 'start_date'
    }, 
    {
      dateValues: values.end_date.split('/'),
      daytime: values.end_daytime,
      hour: values.end_hour,
      min: values.end_min,
      type: 'end_date'
    }];
    // Conflicting dates validation
    for (let n = 0; n < 2; n++) {
      let d = [startDate, endDate][n];
      const {dateValues, daytime, hour, min, type} = d;
      const date = fromUserTimezone(new Date(
        dateValues[2], 
        dateValues[0]-1, 
        dateValues[1], 
        daytime === 'am' ?
          hour === '12' ? 0 : hour :
          hour === '12' ? 12 : parseInt(hour) + 12,
        min
      ));

      for (let i = 0; i < conflictingPromotions.length; i++) {
        let promo = conflictingPromotions[i];
        if (!error && date.getTime() > new Date(promo.start_time).getTime() && date.getTime() < new Date(promo.end_time).getTime()) {
          isError = true;
          setError({message: `Date conflicts with ${promo.name}`, type});
          return false;
        } 
      };
      if (!isError) setError(false);
      return true;
    }
  }
  useEffect(() => {
    setDynamicName(values.name);
    dateValidation(values);
    switch(values.restriction_type) {
      case "all_orders":
        setRestriction(false);
        break;
      case "collections":
        setRestriction('collections');
        break;
      case "products":
        setRestriction('products');
        break;
      default: 
        setRestriction(false);
    }
  }, [values])

  const submit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const subBtn = document.querySelector('.update-promotion-form__column--submit button');
    subBtn.disabled = true;
    let payload = {...values, ids: []};
    if (values.restriction_type !== 'all_orders') {
      payload.ids = values.restriction_type === 'collections' ? collectionIds : productIds;
      if (payload.ids.length === 0) payload.restriction_type = 'all_orders';
    }
    payload._id = id;
    const startHour = values.start_daytime === 'pm' 
      ? values.start_hour === "12" 
        ? "00"
        : parseInt(values.start_hour) + 12
      : values.start_hour;
    const endHour = values.end_daytime === 'pm' 
    ? values.end_hour === "12" 
      ? "00"
      : parseInt(values.end_hour) + 12
    : values.end_hour;
    payload.start_time = fromUserTimezone(new Date(`${values.start_date} ${startHour}:${values.start_min}`));
    payload.end_time = fromUserTimezone(new Date(`${values.end_date} ${endHour}:${values.end_min}`));
    axios.put(
      'https://api.amplifyloyalty.com/app/stores/promotions',
      payload,
      {
        params: {
          jwt: localStorage.getItem('_id'),
          section: 'info'
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        navigate(`/app/promotions/${id}/amplifiers`);
      }
    }).catch (err => {
      // responseHandler(err)
      setSubmitting(false);
      console.log(err);
      subBtn.disabled = false;
    })
  }

  return (
    <section className="update-promotion">
      <div className="block-section">
        <form className="update-promotion-form__form" onSubmit={submit}>
          <div className="update-promotion-form__row">
            <div className="update-promotion-form__column">
              <label htmlFor="name" className="update-promotion-form__label">
                Name
              </label>
              <input className="update-promotion-form__field" name="name" value={values.name} onChange={(e) => {updateValue('name', e.target.value)}} />
            </div>
            <div className="update-promotion-form__column">
              <label htmlFor="multiplier" className="update-promotion-form__label">
                Multiplier
              </label>
              <div className="update-promotion-form__multiplier">
                <div className={`update-promotion-form__multiplier-icon${submitting ? ' input-disabled' : ''}`}>x</div>
                <input className="update-promotion-form__field update-promotion-form__multiplier-field" name="multiplier" type="number" value={values.multiplier} onChange={(e) => {updateValue('multiplier', e.target.value)}}/>
                <div className="update-promotion-form__multiplier-identifier">points</div>
              </div>
            </div>
          </div>
          <div className="update-promotion-form__row">
            <div className="update-promotion-form__column">
              <label className="update-promotion-form__label" htmlFor="restriction_type">Applies to</label>
              <div className="update-promotion-form__fields">
                <select name="restriction_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={values.restriction_type} onChange={(e) => {updateValue('restriction_type', e.target.value)}}>
                  <option value="all_orders">All orders</option>
                  <option value="collections">Specific collections</option>
                  <option value="products">Specific products</option>
                </select>
              </div>
            </div>
            {values && 
              <div className="update-promotion-form__column update-promotion-form__column--flex">
                <div className="update-promotion-form__half-column">
                  <label className="update-promotion-form__label">
                    Start time
                  </label>
                  <div className="update-promotion-form__fields">
                    <DatePicker
                      containerClass="update-promotion-form__field-container" 
                      inputClass="update-promotion-form__field--special"
                      dateName="start_date"
                      submitting={submitting}
                      value={values.start_date}
                      onChange={updateValue}
                    />
                    <TimePicker 
                      className="update-promotion-form__field--special" 
                      hourName="start_hour"
                      minName="start_min"
                      daytimeName="start_daytime"
                      submitting={submitting}
                      values={values}
                      onChange={updateValue}
                    />
                  </div>
                  {error && error.type === 'start_date' && 
                    <div className="update-promotion-form__error">{error.message}</div>
                  }
                </div>
                <div className="update-promotion-form__half-column">
                  <label className="update-promotion-form__label">
                    End time
                  </label>
                  <div className="update-promotion-form__fields">
                    <DatePicker
                      containerClass="update-promotion-form__field-container" 
                      inputClass="update-promotion-form__field--special"
                      dateName="end_date"
                      submitting={submitting}
                      value={values.end_date}
                      onChange={updateValue}
                    />
                    <TimePicker 
                      className="update-promotion-form__field--special" 
                      hourName="end_hour"
                      minName="end_min"
                      daytimeName="end_daytime"
                      submitting={submitting}
                      values={values}
                      onChange={updateValue}
                    />
                  </div>
                  {error && error.type === 'end_date' && 
                    <div className="update-promotion-form__error">{error.message}</div>
                  }
                </div>
              </div>
            }
          </div>
          {restriction && 
            <>
              <div className="vip-form__add-bonus" onClick={() => {if (!submitting) {setSearchPreview(true)}}}>+ {restriction === 'collections' ? "Collection" : "Products"}</div>
              <PicksDisplay 
                picks={restriction === 'collections' ? collectionIds : productIds} 
                setPicks={restriction === 'collections' ? setCollectionIds : setProductIds}  
              />
            </>
          }
          <div className="update-promotion-form__column--submit">
            {!error && 
              <button type="submit" className="btn gradient">Continue</button>
            }
          </div>
        </form>
        {searchPreview && 
          <div className="search-preview-container">
            <SimpleSearchPreview
              close={setSearchPreview}
              heading={restriction === 'collections' ? 'Add collections' : 'Add products'}
              formValues={restriction === 'collections' ? [collectionIds, setCollectionIds] : [productIds, setProductIds]}
              endpoint={restriction === 'collections' ? 'collections' : 'products'}
              className='update-promotion__search-preview'
              promotion={true}
            />
          </div>
        }
      </div>
    </section>
  )
}

export const PromotionsAmplifiers = (props) => {
  const { promotion,
    activeAmplifier,
    setActiveAmplifier,
    announcementStatus,
    productStatus,
    collectionStatus,
    cartStatus,
    submitting
  } = props;
  
  const toggleActiveForm = (e, r, n) => {
    if (!submitting) {
      setActiveAmplifier(n);
      if (!e.currentTarget.classList.contains('update-promotion-amplifiers__amplifier-header--active')) {
        document.querySelector('.update-promotion-amplifiers__amplifier-header--active').classList.remove('update-promotion-amplifiers__amplifier-header--active');
        e.currentTarget.classList.add('update-promotion-amplifiers__amplifier-header--active');
      }
    }
  }

  return (
    <section className="update-promotion-amplifiers block-section">
      <div className="update-promotion-amplifiers__header-wrapper">
        <div className="update-promotion-amplifiers__header h2">Settings</div>
        <ToolTip message="Amplifiers need to be currently active on site to take effect during promotion. Read more here." />
      </div>
      <div className="update-promotion-amplifiers__settings">
        <div data-row="1" className="update-promotion-amplifiers__amplifier">
          <div onClick={(e) => {toggleActiveForm(e, "1", 'announcementBar')}} className="update-promotion-amplifiers__amplifier-header update-promotion-amplifiers__amplifier-header--active">
            <div className="update-promotion-amplifiers__title-wrapper">
              <div className="update-promotion-amplifiers__amplifier-title">Announcement Bar</div>
              <div className={`update-promotion-amplifiers__amplifier-status${announcementStatus ? ' update-promotion-amplifiers__amplifier-status--on' : ''}`}>{announcementStatus ? 'ON' : 'OFF'}</div>
            </div>
            <i className="update-promotion-amplifiers__chevron fa-solid fa-chevron-down"></i>
          </div>
          {activeAmplifier === 'announcementBar' && 
            <div className="update-promotion-amplifiers__form update-promotion-amplifiers__form--active">
              <div className="update-promotion-amplifiers__input-row">
                <span className="update-promotion-amplifiers__label">Status</span>
                <div className="update-promotion-amplifiers__input-container">
                  <label className="switch">
                    <Field className="switch-input" name="announcement_status" type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="announcement_text">Text</label>
                <div className="update-promotion-amplifiers__input-container">
                  <Field className="update-promotion-amplifiers__input" type="text" name="announcement_text" />
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="announcement_font_size">Font size</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--identify">
                  <Field className="update-promotion-amplifiers__input" type="number" name="announcement_font_size" />
                  <div className="update-promotion-amplifiers__input-identifier-container">
                    <div className="update-promotion-amplifiers__input-identifier">px</div>
                  </div>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="announcement_font_color">Font color</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                  <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="announcement_font_color" color={promotion.amplifiers.announcement.font_color} submitting={submitting}/>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="announcement_background_color">Background color</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                  <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="announcement_background_color" color={promotion.amplifiers.announcement.background_color} submitting={submitting}/>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="announcement_url">URL</label>
                <div className="update-promotion-amplifiers__input-container">
                  <Field className="update-promotion-amplifiers__input" type="text" name="announcement_url" />
                </div>
              </div>
            </div>
          }
        </div>
        {/* <div data-row="2" className="update-promotion-amplifiers__amplifier">
          <div onClick={(e) => {toggleActiveForm(e, "2", 'collections')}} className="update-promotion-amplifiers__amplifier-header">
            <div className="update-promotion-amplifiers__title-wrapper">
              <div className="update-promotion-amplifiers__amplifier-title">Collections</div>
              <div className={`update-promotion-amplifiers__amplifier-status${collectionStatus ? ' update-promotion-amplifiers__amplifier-status--on' : ''}`}>{collectionStatus ? 'ON' : 'OFF'}</div>
            </div>
            <i className="update-promotion-amplifiers__chevron fa-solid fa-chevron-down"></i>
          </div>
          {activeAmplifier === 'collections' && 
            <div className="update-promotion-amplifiers__form">
              <div className="update-promotion-amplifiers__input-row">
                <span className="update-promotion-amplifiers__label">Status</span>
                <div className="update-promotion-amplifiers__input-container">
                  <label className="switch">
                    <Field className="switch-input" name="collection_status" type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="collection_text">Text</label>
                <div className="update-promotion-amplifiers__input-container">
                  <Field className="update-promotion-amplifiers__input" type="text" name="collection_text" />
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="collection_font_size">Font size</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--identify">
                  <Field className="update-promotion-amplifiers__input" type="number" name="collection_font_size" />
                  <div className="update-promotion-amplifiers__input-identifier-container">
                    <div className="update-promotion-amplifiers__input-identifier">px</div>
                  </div>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="collection_font_color">Font color</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                  <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="collection_font_color" color={promotion.amplifiers.collection.font_color} submitting={submitting}/>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="collection_points_color">Point color</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                  <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input input" name="collection_points_color" color={promotion.amplifiers.collection.points_color} submitting={submitting}/>
                </div>
              </div>
            </div>
          }
        </div> */}
        <div data-row="3" className="update-promotion-amplifiers__amplifier">
          <div onClick={(e) => {toggleActiveForm(e, "3", 'products')}} className="update-promotion-amplifiers__amplifier-header">
            <div className="update-promotion-amplifiers__title-wrapper">
              <div className="update-promotion-amplifiers__amplifier-title">Products</div>
              <div className={`update-promotion-amplifiers__amplifier-status${productStatus ? ' update-promotion-amplifiers__amplifier-status--on' : ''}`}>{productStatus ? 'ON' : 'OFF'}</div>
            </div>
            <i className="update-promotion-amplifiers__chevron fa-solid fa-chevron-down"></i>
          </div>
          {activeAmplifier === 'products' && 
            <div className="update-promotion-amplifiers__form">
              <div className="update-promotion-amplifiers__input-row">
                <span className="update-promotion-amplifiers__label">Status</span>
                <div className="update-promotion-amplifiers__input-container">
                  <label className="switch">
                    <Field className="switch-input" name="product_status" type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="product_text">Text</label>
                <div className="update-promotion-amplifiers__input-container">
                  <Field className="update-promotion-amplifiers__input" type="text" name="product_text" />
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="product_font_size">Font size</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--identify">
                  <Field className="update-promotion-amplifiers__input" type="number" name="product_font_size" />
                  <div className="update-promotion-amplifiers__input-identifier-container">
                    <div className="update-promotion-amplifiers__input-identifier">px</div>
                  </div>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="product_font_color">Font color</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                  <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="product_font_color" color={promotion.amplifiers.product.font_color} submitting={submitting}/>
                </div>
              </div>
              <div className="update-promotion-amplifiers__input-row">
                <label className='update-promotion-amplifiers__label' htmlFor="product_points_color">Point color</label>
                <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                  <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="product_points_color" color={promotion.amplifiers.product.points_color} submitting={submitting}/>
                </div>
              </div>
            </div>
          }
        </div>
        <div data-row="4" className="update-promotion-amplifiers__amplifier">
          <div onClick={(e) => {toggleActiveForm(e, "4", 'cart')}} className="update-promotion-amplifiers__amplifier-header">
            <div className="update-promotion-amplifiers__title-wrapper">
              <div className="update-promotion-amplifiers__amplifier-title">Cart</div>
              <div className={`update-promotion-amplifiers__amplifier-status${cartStatus ? ' update-promotion-amplifiers__amplifier-status--on' : ''}`}>{cartStatus ? 'ON' : 'OFF'}</div>
            </div>
            <i className="update-promotion-amplifiers__chevron fa-solid fa-chevron-down"></i>
          </div>
          {activeAmplifier === 'cart' && 
            <div className="update-promotion-amplifiers__form">
              <div className="update-promotion-amplifiers__divider">Cart Announcement</div>
                <div className="update-promotion-amplifiers__input-row">
                  <span className="update-promotion-amplifiers__label">Status</span>
                  <div className="update-promotion-amplifiers__input-container">
                    <label className="switch">
                      <Field className="switch-input" name="cartpoints_point_status" type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartpoints_text">Text</label>
                  <div className="update-promotion-amplifiers__input-container">
                    <Field className="update-promotion-amplifiers__input" type="text" name="cartpoints_text" />
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartpoints_font_size">Font size</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--identify">
                    <Field className="update-promotion-amplifiers__input" type="number" name="cartpoints_font_size" />
                    <div className="update-promotion-amplifiers__input-identifier-container">
                      <div className="update-promotion-amplifiers__input-identifier">px</div>
                    </div>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartpoints_font_color">Font color</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                    <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="cartpoints_font_color" color={promotion.amplifiers.cart.points_display.font_color} submitting={submitting}/>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartpoints_points_color">Point color</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                    <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="cartpoints_points_color" color={promotion.amplifiers.cart.points_display.points_color} submitting={submitting}/>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartpoints_background_color">Background color</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                    <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="cartpoints_background_color" color={promotion.amplifiers.cart.points_display.background_color} submitting={submitting}/>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartpoints_border_color">Border color</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                    <ColorPicker className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" name="cartpoints_border_color" color={promotion.amplifiers.cart.points_display.border_color} submitting={submitting}/>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__divider">In-Cart Redemptions</div>
                <div className="update-promotion-amplifiers__input-row">
                  <span className="update-promotion-amplifiers__label">Status</span>
                  <div className="update-promotion-amplifiers__input-container">
                    <label className="switch">
                      <Field className="switch-input" name="cartredemptions_redemption_status" type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartredemptions_redemption_font_size">Font size</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--identify">
                    <Field className="update-promotion-amplifiers__input" type="number" name="cartredemptions_redemption_font_size" />
                    <div className="update-promotion-amplifiers__input-identifier-container">
                      <div className="update-promotion-amplifiers__input-identifier">px</div>
                    </div>
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartredemptions_redemption_font_color">Font color</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                    <ColorPicker 
                    className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" 
                    name="cartredemptions_redemption_font_color" 
                    color={promotion.amplifiers.cart.redemptions.redemption_font_color} 
                    submitting={submitting}
                    top={true}  
                  />
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartredemptions_redemption_background_color">Background color</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                    <ColorPicker 
                    className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" 
                    name="cartredemptions_redemption_background_color" 
                    color={promotion.amplifiers.cart.redemptions.redemption_background_color} 
                    submitting={submitting} 
                    top={true}
                  />
                  </div>
                </div>
                <div className="update-promotion-amplifiers__input-row">
                  <label className='update-promotion-amplifiers__label' htmlFor="cartredemptions_redemption_border_color">Border color</label>
                  <div className="update-promotion-amplifiers__input-container update-promotion-amplifiers__input-container--color">
                    <ColorPicker 
                    className="update-promotion-amplifiers__input update-promotion-amplifiers__input--color input" 
                    name="cartredemptions_redemption_border_color" 
                    color={promotion.amplifiers.cart.redemptions.redemption_border_color} 
                    submitting={submitting}
                    top={true}  
                  />
                  </div>
                </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

// Amplifier Preview Components
const PreviewNav = (props) => {
  const {mobile} = props;
  return (
    <div className="navigation-preview announcement-bar-preview__nav">
        <img className="navigation-preview__logo" src={icon} alt="Preview Logo" />
        {!mobile && 
          <CreateText chars={7} min={25} max={35} styles={{height: '10px'}} classes="navigation-preview__links" />
        }
        {mobile && 
          <CreateText chars={3} min={20} max={20} styles={{height: '2px'}} classes="navigation-preview__hamburger" />
        }
    </div>
  )
}

export const AnnouncementBarPreview = (props) => {
  const {announcement_status, announcement_text, announcement_background_color, announcement_font_color, announcement_font_size} = props.values;
  const {mobile} = props;
  let styles = {
    backgroundColor: announcement_background_color,
    color: announcement_font_color,
    fontSize: announcement_font_size
  }

  return (
    <div className={`amplifier-preview-display announcement-bar-preview`}>
      {announcement_status && 
        <div style={styles} className="announcement-bar-preview__announcement-bar">
          {announcement_text}
        </div>
      }
      <PreviewNav mobile={mobile} />
      <div className="announcement-bar-preview__header">
        <CreateText chars={3} min={15} max={30} styles={{height: '10px'}} classes="announcement-bar-preview__header-headline" />
        <CreateText chars={7} min={10} max={25} classes="announcement-bar-preview__header-description" />
        <CreateText chars={2} min={5} max={10} styles={{margin: '0 5px 0 0'}} classes="announcement-bar-preview__header-cta" />
      </div>
      <div className="announcement-bar-preview__products">
        <ProductCard classes="announcement-bar-preview" />
        <ProductCard classes="announcement-bar-preview" />
        <ProductCard classes="announcement-bar-preview" />
        <ProductCard classes="announcement-bar-preview" />
        <ProductCard classes="announcement-bar-preview" />
      </div>
      <div className="announcement-bar-preview__feature">
        <div className="announcement-bar-preview__feature-image">
          <i className="fa-regular fa-image"></i>
        </div>
        <div className="announcement-bar-preview__feature-text">
          <CreateText chars={4} min={20} max={45} styles={{height: '12px', margin: '0 5px 0 0'}} classes="announcement-bar-preview__feature-header" />
          <CreateText chars={55} min={10} max={25} classes="announcement-bar-preview__feature-description" />
        </div>
      </div>
    </div>
  )
}

export const CollectionsPreview = (props) => {
  const {values, mobile} = props;
  const styles = {
    text: values.collection_text, 
    font_color: values.collection_font_color, 
    points_color: values.collection_points_color, 
    font_size: values.collection_font_size
  }
  return (
    <div className={`amplifier-preview-display collections-preview`}>
      <PreviewNav mobile={mobile} />
      <div className="collections-preview__product-grid">
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
        <ProductCard classes="collections-preview" title={{min: 12, max: 22}} styles={styles} status={values.collection_status}/>
      </div>
    </div>
  )
}

export const ProductsPreview = (props) => {
  let dynamicText;
  const {values, mobile} = props;
  if (values.product_text.includes('%points%')) {
    let textArr = values.product_text.split('%points%');
    dynamicText = <div className="products-preview__points" style={{fontSize: values.product_font_size, color: values.product_font_color}}>
        {textArr[0]}<span style={{fontSize: values.product_font_size, color: values.product_points_color, fontWeight: 'bold'}}>20 Points</span>{textArr[1]}
      </div>
  } else {
    dynamicText = <div className="products-preview__points" style={{fontSize: values.product_font_size, color: values.product_font_color}}>{values.product_text}</div>
  }
  
  return (
    <div className={`amplifier-preview-display products-preview`}>
      <PreviewNav mobile={mobile} />
      <div className={`products-preview__product${mobile ? ' products-preview__product--mobile' : ''}`}>
        <div className="products-preview__image">
          <i className={`fa-solid fa-shirt products-preview__shirt`}></i>
        </div>
        <div className="products-preview__info">
          <CreateText chars={3} min={25} max={55} styles={{height: '16px', margin: '0 5px 0 0'}} classes="products-preview__title" />
          <CreateText chars={1} min={19} max={20} styles={{paddingTop: '3px', paddingBottom: '5px', paddingLeft: '3px'}} classes="products-preview__price">$</CreateText>
          <CreateText chars={20} min={8} max={15} styles={{height: '8px'}} classes="products-preview__description" />
          {values.product_status && dynamicText}
          <div className="products-preview__quantity">
            <span className="products-preview__adjustment">-</span><span className="preview__adjustment">1</span><span className="products-preview__adjustment">+</span>
          </div>
          <CreateText chars={2} min={25} max={30} styles={{height: '12px', margin: '0 5px 0 0'}} classes="products-preview__cta" />
        </div>
      </div>
      <div className="products-preview__related-products">
        <CreateText chars={2} min={50} max={75} styles={{height: '15px', margin: '0 5px 0 0'}} classes="products-preview__related-products-header" />
        <div className="products-preview__products-grid">
          <ProductCard title={{min: 15, max: 25, styles: {margin: '0 5px 0 0', height: '8px'}}} classes="products-preview" />
          <ProductCard title={{min: 15, max: 25, styles: {margin: '0 5px 0 0', height: '8px'}}} classes="products-preview" />
          <ProductCard title={{min: 15, max: 25, styles: {margin: '0 5px 0 0', height: '8px'}}} classes="products-preview" />
          <ProductCard title={{min: 15, max: 25, styles: {margin: '0 5px 0 0', height: '8px'}}} classes="products-preview" />
        </div>
      </div>
    </div>
  )
}
  
export const CartPreview = (props) => {
  const {values} = props;
  return (
    <div className={`amplifier-preview-display cart-preview ${props.display ? 'amplifier-preview--show' : ''}`} >
      <PreviewNav />
      <CartAnnouncementBar values={values} />
      <CartItems />
      <CartRedemptions values={values} />
    </div>
  )
}

const CartAnnouncementBar = (props) => {
  const {values} = props;
  
  let html;
  if (values.cartpoints_text.includes('%points%')) {
    let textArr = values.cartpoints_text.split('%points%');
    html = <div className={`${props.classes}__product-points`} style={{fontSize: values.cartpoints_font_size, color: values.cartpoints_font_color}}>
        {textArr[0]}<span style={{color: values.cartpoints_points_color, fontWeight: 'bold'}}>20 Points</span>{textArr[1]}
      </div>
  } else {
    html = values.cartpoints_text;
  }

  return(
    <>
      {values.cartpoints_point_status && 
        <div className="cart-preview__announcement-bar" style={{backgroundColor: values.cartpoints_background_color, border: `1px solid ${values.cartpoints_border_color}`}}>
          <div className="cart-preview__announcement-text" style={{fontSize: values.cartpoints_font_size, color: values.cartpoints_font_color}}>
            {html}
          </div>
        </div>
      }
    </>
  )
}

const CartItems = (props) => {
  return (
    <div className="cart-preview__cart-items">
      <div className="cart-preview__cart-header">
        <CreateText chars={2} min={30} max={35} styles={{height: '13px', marginRight: '7px'}} />
        <CreateText chars={2} min={30} max={35} styles={{marginRight: '5px', height: '8px'}} />
      </div>
      <div className="cart-preview__product">
        <div className="cart-preview__product-info">
          <i className="fa-solid fa-shirt cart-preview__product-image"></i>
          <div className="cart-preview__item-description">
            <CreateText chars={1} min={15} max={20} />
            <CreateText chars={3} min={25} max={35} styles={{height: '12px', marginRight: '5px'}} classes="cart-preview__product-title" />
            <CreateText chars={1} min={19} max={20} styles={{paddingTop: '2px', paddingBottom: '5px', paddingLeft: '3px'}} classes="cart-preview__price">$</CreateText>
          </div>
        </div>
        <div className="cart-preview__product-quantity">
          <CreateText chars={1} min={15} max={20} />
          <CreateText chars={1} min={30} max={30} styles={{height: '12px', marginRight: '5px'}} />
        </div>
      </div>
      <div className="cart-preview__product">
        <div className="cart-preview__product-info">
          <i className="fa-solid fa-shirt cart-preview__product-image"></i>
          <div className="cart-preview__item-description">
            <CreateText chars={3} min={25} max={35} styles={{height: '12px', marginRight: '5px'}} classes="cart-preview__product-title" />
            <CreateText chars={1} min={19} max={20} styles={{paddingTop: '2px', paddingBottom: '5px', paddingLeft: '3px'}} classes="cart-preview__price">$</CreateText>
          </div>
        </div>
        <div className="cart-preview__product-quantity">
          <CreateText chars={1} min={30} max={30} styles={{height: '12px', marginRight: '5px'}} />
        </div>
      </div>
      <hr className="cart-preview__divider" />
    </div>
  )
}

const CartRedemptions = (props) => {
  const {values} = props;
  const CouponCard = (props) => {
    return (
      <>
        {values.cartredemptions_redemption_status && 
          <div className="cart-preview__coupon-card" style={{backgroundColor: values.cartredemptions_redemption_background_color, color: values.cartredemptions_redemption_font_color}}>
            <div className="cart-preview__coupon-card-inner" style={{borderColor: values.cartredemptions_redemption_border_color}}>
              <div className="cart-preview__coupon" style={{fontSize: values.cartredemptions_redemption_font_size}}>{props.coupon}</div>
            </div>
          </div>
        }
      </>
    )
  }

  return (
    <div className="cart-preview__redeemables">
      <CreateText chars={2} min={35} max={40} styles={{marginRight: '5px', height: '8px'}} classes="cart-preview__redeemables-header" />
      <CreateText chars={2} min={35} max={40} styles={{marginRight: '15px', height: '10px'}} classes="cart-preview__redeemables-header" />
      <div className="cart-preview__coupon-cards">
        <CouponCard coupon="10% off" />
        <CouponCard coupon="$5 off" />
        <CouponCard coupon="$10 off" />
      </div>
      <CreateText chars={2} min={25} max={30} styles={{height: '12px', margin: '0 5px 0 0'}} classes="cart-preview__cta" />
    </div>
  )
}


export const PromotionsPreview = (props) => {
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();
  const {previewValues, activeAmplifier} = props;

  const toggleMobile = (bool, t) => {
    setMobile(bool);
    if (!t.classList.contains('amplifier-preview__icon-wrapper--active')) {
      document.querySelector('.amplifier-preview__icon-wrapper--active').classList.remove('amplifier-preview__icon-wrapper--active');
      t.classList.add('amplifier-preview__icon-wrapper--active');
    }
  }

  return (
    <section className="update-promotion-preview block-section">
      <div className="update-promotion-preview__wrapper">
        <div className="amplifier-preview__continue-button-wrapper">
          <button className="amplifier-preview__continue-button gradient" type="submit">Continue</button>
        </div>
        <div className="amplifier-preview__disclaimer">
          <ToolTip 
            message='This is an example of what it will look like on your site but not a direct representation. It is recommended that you use style options similar to the ones already set in the Shopify Theme Editor.'
            height="67px"
            width="300px"
          />
        </div>
        <div className="update-promotion-preview__center-wrap">
          <div className="amplifier-preview__device-toggle">
            <div onClick={(e) => {toggleMobile(false, e.currentTarget)}} className="amplifier-preview__icon-wrapper amplifier-preview__device--desktop amplifier-preview__icon-wrapper--active">
              <i className="amplifier-preview__icon fa-solid fa-display"></i>
            </div>
            <div className="divider"></div>
            <div onClick={(e) => {toggleMobile(true, e.currentTarget)}} className="amplifier-preview__icon-wrapper amplifier-preview__device--mobile">
              <i className="amplifier-preview__icon fa-solid fa-mobile-screen"></i>
            </div>
          </div>
          {!mobile && 
            <div className="update-promotion-preview__display-desktop">
              <div className="amplifier-preview__desktop">
                    <div className="amplifier-preview__desktop-screen">
                      {activeAmplifier === 'announcementBar' && 
                        <AnnouncementBarPreview values={previewValues} mobile={mobile} />
                      }
                      {activeAmplifier === 'collections' && 
                        <CollectionsPreview values={previewValues} mobile={mobile} />
                      }
                      {activeAmplifier === 'products' && 
                        <ProductsPreview values={previewValues} mobile={mobile} />
                      }
                      {activeAmplifier === 'cart' && 
                        <CartPreview values={previewValues} mobile={mobile} />
                      }
                    </div>
                    <div className="amplifier-preview__desktop-stand"></div>
                  </div>
            </div>
          }
          {mobile && 
            <div className="update-promotion-preview__display-mobile">
              <div className="amplifier-preview__mobile-screen">
                {activeAmplifier === 'announcementBar' && 
                  <AnnouncementBarPreview values={previewValues} mobile={mobile} />
                }
                {activeAmplifier === 'collections' && 
                  <CollectionsPreview values={previewValues} mobile={mobile} />
                }
                {activeAmplifier === 'products' && 
                  <ProductsPreview values={previewValues} mobile={mobile} />
                }
                {activeAmplifier === 'cart' && 
                  <CartPreview values={previewValues} mobile={mobile} />
                }
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

// Amplifier settings
export const AmpHeader = (props) => {
  const {name, location, iconClasses, title} = props;

  const toggleOptions = async (e, t) => {
    try {
      const activeChevron = document.querySelector('.amplifier__chevron--expand');
      // When opening a section that isn't currently open
      if (activeChevron && e.target !== activeChevron) {
        activeChevron.classList.toggle('amplifier__chevron--expand');
        document.querySelector('.amplifier__customizations--show').classList.toggle('amplifier__customizations--show');
        document.querySelector(`${t} > .amplifier__customizations`).classList.toggle('amplifier__customizations--show');
        e.target.classList.toggle('amplifier__chevron--expand');
        // Hide active preview and show new one
        // document.querySelector('.amplifier-preview--show').classList.remove('amplifier-preview--show');
        // document.querySelector(`.${name}-preview`).classList.toggle('amplifier-preview--show');
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="amplifier__header">
      <div className="amplifier__title"><i className={`amplifier__icon ${iconClasses}`}></i>{title}</div>
      <i className={`amplifier__chevron ${location === '1' ? `amplifier__chevron--expand` : ''} fa-solid fa-chevron-right`} onClick={(event) => {toggleOptions(event, `.amplifier__${name}`)}}></i>
    </div>
  )

}

export const SubmissionError = (props) => {
  const {error} = props;

  return (
    <div className={`update-promotion-review__error${error.level === 'critical' ? ' update-promotion-review__error--critical' : ''}`}>
      <WarningIcon size={20} color={error.level === 'critical' ? "#F51504" : "#F8A305"} />
      <div className="update-promotion-review__error-message">{error.message}</div>
    </div>
  )
}

export const PromotionReview = (props) => {
  const {promotion, disableReviewInputs, id} = props;
  const {logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const [start, setStart] = useState(toUserTimezone(new Date(promotion.start_time)));
  const [end, setEnd] = useState(toUserTimezone(new Date(promotion.end_time)));
  const [submissionError, setSubmissionError] = useState(false);
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  useEffect(() => {
    console.log({start, end})
    if (fromUserTimezone(end) < new Date()) {
      setSubmissionError({section: 'schedule', message: 'End date must be in the future.', level: 'critical'});
    } else if (fromUserTimezone(start) < new Date()) {
      setSubmissionError({section: 'schedule', message: 'Start dates in the past will enable the promotion immediately.', level: 'warning'});
    }
  }, [])


  const submitPromotion = () => {
    if (submissionError.level !== 'critical') {
      disableReviewInputs(true);
      axios.put(
        'https://api.amplifyloyalty.com/app/stores/promotions',
        {
          _id: promotion._id,
          status: "scheduled"
        },
        {
          params: {
            jwt: localStorage.getItem('_id'),
            section: 'review'
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          navigate('/app/promotions');
        }
      }).catch (err => {
        // responseHandler(err)
        disableReviewInputs(false);
        console.log(err);
      })
    } else {
      console.log(submissionError);
    }
  }

  return (
    <section className="update-promotion-review centralize block-section">
      {promotion && 
        <div className="update-promotion-review__container">
          <div className="update-promotion-review__title">Review to Schedule</div>
          <div className="update-promotion-review__items">
            <div className="update-promotion-review__item">
              <div className="update-promotion-review__item-icon-wrapper">
                <div className="update-promotion-review__item-icon">i</div>
              </div>
              <div className="update-promotion-review__item-content">
                <div className="update-promotion-review__item-text">
                  <div className="update-promotion-review__item-title">Schedule</div>
                  {/* Show error if end time isn't in the future */}
                  {/* Show warning that start times in the past will start right now */}
                  <div className="update-promotion-review__item-description">
                    Runs from {days[start.getDay()]}, {`${start.getMonth()+1}/${start.getDate()}/${start.getFullYear().toString().slice(2,)} `} 
                    at {`${start.getHours() > 12 ? start.getHours() % 12 : start.getHours() === 0 ? 12 : start.getHours()}:${start.getMinutes() < 10 ? `0${start.getMinutes()}` : start.getMinutes()}${start.getHours() > 11 ? 'pm' : 'am'} `}
                    until {days[end.getDay()]}, {`${end.getMonth()+1}/${end.getDate()}/${end.getFullYear().toString().slice(2,)} `} 
                    at {`${end.getHours() > 12 ? end.getHours() % 12 : end.getHours() === 0 ? 12 : end.getHours()}:${end.getMinutes() < 10 ? `0${end.getMinutes()}` : end.getMinutes()}${end.getHours() > 11 ? 'pm' : 'am'}`}.
                  </div>
                  {submissionError && submissionError.section === 'schedule' &&
                    <SubmissionError error={submissionError} />
                  }
                </div>
                <a className="update-promotion-review__submit-button btn" href={`/app/promotions/${id}`}>Edit</a>
              </div>
            </div>
            <div className="update-promotion-review__item">
              <div className="update-promotion-review__item-icon-wrapper">
                <div className="update-promotion-review__item-icon">i</div>
              </div>
              <div className="update-promotion-review__item-content">
                <div className="update-promotion-review__item-text">
                  <div className="update-promotion-review__item-title">Eligibility</div>
                  <div className="update-promotion-review__item-description">
                    Multiplies points achieved by {promotion.multiplier}x from purchasing 
                    {promotion.restriction_type === 'all_orders' && ` all products`}
                    {promotion.restriction_type === 'collections' && ` ${promotion.ids.length} collections`}
                    {promotion.restriction_type === 'products' && ` ${promotion.ids.length} products`}
                    .
                  </div>
                </div>
                <a className="update-promotion-review__submit-button btn" href={`/app/promotions/${id}`}>Edit</a>
              </div>
            </div>
            <div className="update-promotion-review__item">
              <div className="update-promotion-review__item-icon-wrapper">
                <div className="update-promotion-review__item-icon">i</div>
              </div>
              <div className="update-promotion-review__item-content">
                <div className="update-promotion-review__item-text">
                  <div className="update-promotion-review__item-title">Amplifiers</div>
                  <div className="update-promotion-review__item-description">
                    <div className="update-promotion-review__item-amplifier">
                      <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-bullhorn update-promotion-review__amplifier-icon`}></i> Announcement Bar</div>
                      <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.announcement.status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.announcement.status ? 'ON' : 'OFF'}</div>
                    </div>
                    {/* <div className="update-promotion-review__item-amplifier">
                      <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-bars update-promotion-review__amplifier-icon`}></i> Collections</div>
                      <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.collection.status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.collection.status ? 'ON' : 'OFF'}</div>
                    </div> */}
                    <div className="update-promotion-review__item-amplifier">
                      <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-shirt update-promotion-review__amplifier-icon`}></i> Products</div>
                      <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.product.status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.product.status ? 'ON' : 'OFF'}</div>
                    </div>
                    <div className="update-promotion-review__item-amplifier">
                      <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-cart-shopping update-promotion-review__amplifier-icon`}></i> Cart</div>
                      <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.cart.points_display.point_status || promotion.amplifiers.cart.redemptions.redemption_status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.cart.points_display.point_status || promotion.amplifiers.cart.redemptions.redemption_status ? 'ON' : 'OFF'}</div>
                    </div>
                  </div>
                </div>
                <a className="update-promotion-review__submit-button btn" href={`/app/promotions/${id}/amplifiers`}>Edit</a>
              </div>
            </div>
          </div>
          <div className="update-promotion-review__schedule-button-wrapper">
            <SubmissionError error={{message: 'Once the Promotion is Active it cannot be edited again.'}} />
            <button className="update-promotion-review__schedule-button gradient" onClick={() => submitPromotion()}>Schedule</button>
          </div>
        </div>
      }
    </section>
  )
}