import React, { useContext, useRef, useEffect, useState } from 'react';
import logo from '../assets/images/amplify-loyalty.png';
import { NavLink, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import '../assets/css/main.css';
import '../assets/css/website.css';
import '../assets/css/components/program.css';
import '../App.css';

export default function Header() {
  const {authed, logout} = useContext(AuthContext);
  let location = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [dropdown, setDropdown] = useState({
    features: false
  });
  const mobileRef = useRef(null);

  useEffect(() => {
    setDropdown({features: false})
  }, [mobileMenu])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileRef.current && !mobileRef.current.contains(event.target)) {
        setMobileMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileRef]);

  const toggleLinks = () => {
    setMobileMenu((prevVisible) => !prevVisible);
  };
  
  return (
    <>
      {authed && location.pathname.includes('/app') && 
        <header className="main-header">
          <a href="/app/dashboard"><img id="logo" src={logo} alt="Loyalty App" /></a>
          <ul className="main-header__links">
            <NavLink to="/" onClick={logout}><i className="fa-solid fa-right-from-bracket main-header__logout-button"></i></NavLink>
          </ul>
        </header>
      }
      {!location.pathname.includes('/app') &&
        <header className="main-header">
          <a href="/"><img id="logo" src={logo} alt="Loyalty App" /></a>
          <ul className="main-header__links">
            <div className="main-header__dropdown">
              <div className="main-header__dropdown-header">Features</div>
              <div className="main-header__dropdown-list">
                <a href="/features/earn" className="main-header__dropdown-link">Earn Points</a>
                <a href="/features/redeem" className="main-header__dropdown-link">Redeem Rewards</a>
                <a href="/features/vip" className="main-header__dropdown-link">VIP Program</a>
                <a href="/features/giveaways" className="main-header__dropdown-link">Giveaways</a>
                <a href="/features/promotions" className="main-header__dropdown-link">Promotions</a>
              </div>
            </div>
            <NavLink className="main-header__link" to="/pricing">Pricing</NavLink>
            <NavLink className="main-header__link" to="/contact">Contact</NavLink>
            <NavLink className="main-header__link" to="/login">Login</NavLink>
          </ul>
          <div className="main-header__mobile-menu">
            <div className="main-header__mobile-accordion" onClick={toggleLinks}>
              <div className="main-header__accordion-line"></div>
              <div className="main-header__accordion-line"></div>
              <div className="main-header__accordion-line"></div>
            </div>
            <ul 
              className="main-header__links--mobile"
              ref={mobileRef}
              style={{ display: mobileMenu ? "flex" : "none" }}  
            >
              <div className="main-header__dropdown--mobile">
                <div className="main-header__dropdown-header--mobile" onClick={() => setDropdown({...dropdown, features: !dropdown.features})}>Features</div>
                  {dropdown.features && 
                    <div className="main-header__dropdown-list--mobile">
                      <a href="/features/earn" className="main-header__dropdown-link main-header__dropdown-link--mobile">Earn Points</a>
                      <a href="/features/redeem" className="main-header__dropdown-link main-header__dropdown-link--mobile">Redeem Rewards</a>
                      <a href="/features/vip" className="main-header__dropdown-link main-header__dropdown-link--mobile">VIP Program</a>
                      <a href="/features/giveaways" className="main-header__dropdown-link main-header__dropdown-link--mobile">Giveaways</a>
                      <a href="/features/promotions" className="main-header__dropdown-link main-header__dropdown-link--mobile">Promotions</a>
                    </div>
                  }
              </div>
              <a className="main-header__link main-header__link--mobile" href="/pricing">Pricing</a>
              <a className="main-header__link main-header__link--mobile" href="/contact">Contact</a>
              <a className="main-header__link main-header__link--mobile" href="/login">Login</a>
            </ul>
          </div>
        </header>
      }
    </>
  );
};