import { useState, useEffect, useRef } from "react";
import axios from "axios";

export const ContactForm = (props) => {
  const {heading, body, consume} = props;
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    message: null,
    submission: null
  });
  const [submitted, setSubmitted] = useState(false);

  const updateValue = (field, value) => {
    setValues({...values, [field]: value});
  }

  const checkErrors = async () => {
    try {
      let hasError;
      let tempErrors = {
        name: null,
        email: null,
        message: null
      }
      for await (let key of Object.keys(values)) {
        let value = values[key];
        if (!value) {
          tempErrors = {...tempErrors, [key]: 'Field is required.'};
          hasError = true;
        }
      }
      setErrors(tempErrors);
      return hasError;
    } catch (err) {
      console.log(err);
      return true;
    } 
  }

  const submit = async () => {
    const isErrors = await checkErrors();
    if (!isErrors) {
      const toDisable = [
        ...document.querySelectorAll('.contact-form__input'), 
        document.querySelector('.contact-form__textarea'),
        document.querySelector('.contact-form__button')
      ];
      toDisable.forEach(inp => inp.disabled = true);
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LcZ-UAqAAAAAMao0jmw3OyJhmXggpiMO2fydLl3', { action: 'submit' }).then(async (token) => {
          const formData = {
            ...values,
            recaptchaToken: token,
          };
          // Submit the form data to your server (not shown)
          try {
            const res = await axios.post('https://server.amplifyloyalty.com/web/contact',
              formData,
              {withCredentials: false}
            );
            setSubmitted(true);
          } catch (err) {
            toDisable.forEach(inp => inp.disabled = false);
            console.log({err})
            setErrors({...errors, submission: err.response.data});
          }
        });
      });
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=6LcZ-UAqAAAAAMao0jmw3OyJhmXggpiMO2fydLl3`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className={`contact-form${consume ? " contact-form--consume" : ""}`}>
      {heading && <h2 className="contact-form__heading">{heading}</h2>}
      {body && <p className="contact-form__body">{body}</p>}
      {!submitted && <div className="contact-form__form">
        <div className="contact-form__row">
          <div className="contact-form__field">
            <input className="contact-form__input" type="text" name="name" placeholder="Name" value={values.name} onChange={(e) => updateValue("name", e.target.value)} />
            <div className="contact-form__error">{errors.name}</div>
          </div>
          <div className="contact-form__field">
            <input className="contact-form__input" type="email" name="email" placeholder="Email" value={values.email} onChange={(e) => updateValue("email", e.target.value)}/>
            <div className="contact-form__error">{errors.email}</div>
          </div>
        </div>
        <div className="contact-form__textarea-field">
          <textarea name="message" className="contact-form__textarea" placeholder="What would you like to know more about?" value={values.message} onChange={(e) => updateValue("message", e.target.value)}></textarea>
          <div className="contact-form__error contact-form__error--textarea">{errors.message}</div>
        </div>
        <div className="contact-form__buttons">
          <button className="btn gradient contact-form__button" onClick={submit}>Submit</button>
        </div>
        <div className="contact-form__submission-error">{errors.submission}</div>
      </div>}
      {submitted && <div className="contact-form__success">
        <div className="contact-form__success-message">Thanks for contacting us! We'll be reaching out shortly.</div>
        <div className="contact-form__success-cta">
          <div className="contact-form__success-cta-text">In the meantime, sign up for your free account now.</div>
          <a href="/sign-up" className="contact-form__success-cta-btn btn gradient">Sign Up</a>
        </div>
      </div>}
    </div>
  )

}

export const BlankHeader = (props) => {
  return (
    <div className="page-hero">
      <h1 className="page-hero__heading">{props.heading}</h1>
    </div> 
  )
}

export const CallToAction = () => {
  return (
    <div className="call-to-action">
      <h2 className="call-to-action__heading">SIGN UP FREE</h2>
      <div className="call-to-action__column">
        <p className="call-to-action__body">Get a FREE account for a limited time and start using Amplify Loyalty right now.</p>
        <div className="call-to-action__row">
          <a href="/sign-up" className="call-to-action__button btn gradient">SIGN UP</a>
        </div>
      </div>
    </div>
  )
}