

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy__main-heading">Privacy Policy</h1>
      <h3 className="privacy-policy__sub-heading">Effective Date: 1/1/2022</h3>

      <h2 className="privacy-policy__sub-heading">1. Introduction</h2>
      <p className="privacy-policy__description">Amplify Loyalty LLC (“we,” “our,” or “us”) operates the Amplify Loyalty platform. This privacy policy explains how we collect, use, share, and protect personal information collected through our application and website. By using our services, you agree to the terms of this policy.</p>

      <h2 className="privacy-policy__sub-heading">2. Information We Collect</h2>
      <p className="privacy-policy__description">We collect information from you when you:</p>
      <ul className="privacy-policy__list">
        <li className="privacy-policy__list-item">Register an account: email address, store name, store URL, and password.</li>
        <li className="privacy-policy__list-item">Connect with third-party services like Shopify: customer emails, names, phone numbers, order totals, and dates.</li>
        <li className="privacy-policy__list-item">Interact with our platform: data through cookies and local storage for authentication purposes.</li>
      </ul>
      <p className="privacy-policy__description">We also use cookies, Google Analytics, and Facebook ads pixels for tracking usage and analytics.</p>

      <h2 className="privacy-policy__sub-heading">3. How We Use Your Information</h2>
      <ul className="privacy-policy__list">
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Merchants:</span> We use your data for account management, targeted advertising, marketing, and analytics purposes.</li>
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">End-Customers:</span> We use your data solely to display information relevant to your interactions within the merchant’s loyalty program (e.g., redeeming points).</li>
      </ul>
      <p className="privacy-policy__description">We do not use merchant customer data for any marketing purposes other than providing insights back to the merchant.</p>
      
      <h2 className="privacy-policy__sub-heading">4. Sharing Your Information</h2>
      <p className="privacy-policy__description">We may share your data with trusted third parties, including:</p>
      <ul className="privacy-policy__list">
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Payment Processors:</span> for secure payment handling.</li>
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Marketing Partners:</span> for email marketing and targeted advertising.</li>
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Analytics Providers:</span> for platform usage tracking.</li>
      </ul>
      <p className="privacy-policy__description">These partners are only granted access to information necessary for them to perform their services.</p>

      <h2 className="privacy-policy__sub-heading">5. Data Storage & Security</h2>
      <ul className="privacy-policy__list">
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Storage:</span> We store your data securely through our cloud provider.</li>
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Security:</span> We implment secure access protocols to protect your information.</li>
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Retention:</span> Data is stored for as long as your account is active. For inactive accounts, data is retained for up to 2 years and deleted upon request.</li>
      </ul>

      <h2 className="privacy-policy__sub-heading">6. Your Rights</h2>
      <ul className="privacy-policy__list">
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Access & Update:</span> You can access and update your personal information within the platform.</li>
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Deletion:</span> You can request deletion of your data, subject to applicable legal and operational requirements.</li>
        <li className="privacy-policy__list-item"><span className="privacy-policy__important">Policy Changes:</span> We will notify you of significant changes to this policy via email and upon login.</li>
      </ul>

      <h2 className="privacy-policy__sub-heading">7. International Considerations</h2>
      <p className="privacy-policy__description">We comply with applicable privacy regulations, including California Consumer Privacy Act (CCPA) for residents of California. While we currently do not operate in the EU, we are assessing GDPR compliance for potential future expansion.</p>

      <h2 className="privacy-policy__sub-heading">8. Third-Party Integrations</h2>
      <p className="privacy-policy__description">Our platform integrates with third-party services like Shopify, which may collect and process user data when you connect your account. These integrations are optional and require your consent.</p>

      <h2 className="privacy-policy__sub-heading">9. Contact Us</h2>
      <p className="privacy-policy__description">If you have any questions about this privacy policy or our data practices, please contact us at: <a href="mailto:trevor@amplifyloyalty.com">trevor@amplifyloyalty.com</a>.</p>
    </div>
  )
}