import { CallToAction } from "../../components/website/Utility";
import { EarnHero, Earnings, MarketingStack, EarningOptions } from "../../components/WebsiteComponents";
import '../../assets/css/website/component-earn-hero.css';
import '../../assets/css/website/component-earnings-section.css';
import '../../assets/css/website/component-marketing-stack.css';
import '../../assets/css/website/component-earning-options.css';

export default function Earn() {
  return (
    <>
      <EarnHero 
        header="EARN"
        body="Create a unique experience for your customers, rewarding them for specific engagements with your brand."
        img="https://amplify-loyalty-website.s3.amazonaws.com/1-I-Mac+(1).png"
      />
      <Earnings 
        header="YOU SET THE RULES"
        body={[
          'Set rules for customers to earn points for your store',
          'Activate and disable any time',
          'Create your own parameters',
          'Easy and quick setup',
          'Tons of ways to reward'
        ]}
        img="https://amplify-loyalty-website.s3.amazonaws.com/2-Tools+(1).png"
        mImg="https://amplify-loyalty-website.s3.amazonaws.com/Earnings+Options.png"
      />
      <MarketingStack 
        header="INTEGRATE YOUR MARKETING STACK"
        body="Connect your favorite marketing platforms and reward customers for the actions that matter the most."
        image="https://amplify-loyalty-website.s3.amazonaws.com/2-Smartphones+(1).png"
      />
      <EarningOptions 
        header="REDEEM IN ACCOUNT"
        body="Customers can redeem these discounts within their account on your store as soon as you make them active."
        img="https://amplify-loyalty-website.s3.amazonaws.com/3-Icons+(1)+(1).png"
      />
      <CallToAction />
    </>
  )
}