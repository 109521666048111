import React, { useContext, useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
import {
  Loading,
  Logo
} from '../../components/utility/Utility';

export default function Login() {
  const navigate = useNavigate();
  const { login, authed, getTkn } = useContext(AuthContext);
  const [submitting, setSubmitting] = useState(false);
  const [csrf, setCsrf] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  useEffect(() => {
    if (authed) navigate('/app/dashboard')
    else {
      getTkn('/app/auth/login', setCsrf);
    }
  }, [authed, getTkn, navigate]);

  useEffect(() => {
    let toDisable = [...document.querySelectorAll('#login-form input')];
    toDisable.forEach(inp => inp.disabled = submitting);
  }, [submitting])

  return (
    <div className="login">
      <div className="form-container">
        <Logo className="login__logo" />
        <hr className="login__hr" />
        <Formik 
          initialValues={{
            email: '', 
            password: '',
            csrf
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Email is required'),
            password: Yup.string()
              .required('Password is required'),
          })}
          onSubmit={async (values) => {
            if (csrf) {
              setSubmitting(true);
              setFormErrors(false);
              login(values).then(headers => {
                if (headers['x-amplify-ret']) {
                  navigate(`/app/integrations/shopify?ret=${headers['x-amplify-ret']}`);
                } else {
                  navigate('/app/dashboard');
                }
              }).catch(err => {
                if (err.response && err.response.status === 403) {
                  setFormErrors(err.response.data);
                  getTkn('/app/auth/login', setCsrf);
                } else if (err.response && err.response.status === 401) {
                  setFormErrors('New token needed, refresh the page.');
                } else {
                  setFormErrors('Server error, please try again later.');
                }
              }).finally(() => {
                setSubmitting(false);
              });
            }
          }}
          enableReinitialize={true}
        >
          <Form id="login-form" className="auth-form">
            <Field name="csrf" type="hidden" />
            <label className="login-form__label" htmlFor="email">Email</label>
            <Field name="email" type="email" placeholder="customer@gmail.com" />
            <ErrorMessage name="email" component="div" className="error-message" />

            <label className="login-form__label" htmlFor="password">Password</label>
            <Field name="password" type="password" />
            <ErrorMessage name="password" component="div" className="error-message" />

            {formErrors && <div className="form-errors">{formErrors}</div>}
            {submitting && <Loading />}
            {!submitting && 
              <>
                <a className="login-form__text" href="/reset-password">Forgot your password?</a>
                <button type="submit" className="gradient">Sign In</button>
                <a className="login-form__text" href="/sign-up">Create Account</a>
              </>
            }
          </Form>
        </Formik>
      </div>
    </div>
  )
}
