import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Loading, CloseButton, responseHandler, titleCaseText, FormError,SimpleSearchPreview, DatePicker, TimePicker, SearchPreview, PicksDisplay, SimplePicksDisplay } from './utility/Utility';
import '../assets/css/components/program.css';
import { notStrictEqual } from 'assert';

export const NewRedemptionForm = (props) => {
  const {coupon, setCreatingCoupon, program, setProgram, notIntegrated} = props;
  const {logout} = useContext(AuthContext);
  const [valueSign, setValueSign] = useState(false);
  const [searchPreview, setSearchPreview] = useState(false);
  const [errors, setErrors] = useState(false);
  const [formValues, setFormValues] = useState(typeof coupon === 'object' ? coupon : {
    name: '',
    status: "active",
    reward_type: 'currency_off',
    description: '',
    expires: false,
    days_available: 7,
    minimum: false,
    minimum_requirement: 0,
    cost: 0,
    value: 0,
    usage_limit: true,
    usage_limit_value: 1,
    shipping_threshold: false,
    shipping_threshold_value: 0,
    items: {
      restriction_type: 'all_orders',
      ids: []
    }
  });

  useEffect(() => {
    if (coupon.status === 'archived') {
      const inputs = document.querySelectorAll('.redemption-form__input');
      const identifiers = document.querySelectorAll('.redemption-form__input-identifier');
      for (let inp of inputs) {
        inp.disabled = true;
      }
      for (let id of identifiers) {
        id.classList.add('input-disabled')
      }
    }
  }, [valueSign])

  useEffect(() => {
    // Show extra fields unless Free Shipping type
    switch (formValues.reward_type) {
      case "currency_off":
        setValueSign("$");
        break;
      case "percent_off":
        setValueSign("%");
        break;
      case "free_shipping":
        setValueSign(false);
        break;
      default: 
        setValueSign("$");
    }

  }, [formValues.reward_type]);
  
  const toggleDisable = () => {
    let redemptionInputs = document.querySelectorAll('.redemption-form-container input');
    let redemptionButtons = document.querySelectorAll('.redemption-form__button-container > *');
    [...redemptionInputs, ...redemptionButtons].forEach(target => {
      target.disabled = !target.disabled;
    });
  }

  const archiveRedemption = () => {
    if (coupon.status !== 'archived') {
      if (window.confirm("Are you sure you want to archive this coupon? This can't be undone.")) {
        toggleDisable();
        axios.delete('https://api.amplifyloyalty.com/app/stores/redemptions',
          {
            data: coupon,
            params: {
              jwt: localStorage.getItem('_id')
            }
          }
        ).then(res => {
          if (res.data.name === 'BadRequestError') {
            logout();
          } else {
            setCreatingCoupon(false);
            responseHandler(res, "success");
            // Remove redemption from program state
            setProgram(res.data);
          }
        }).catch(err => {
          responseHandler(err);
          toggleDisable();
        })
      }
    }
  }

  const validate = async () => {
    let errs = [];
    let nameRe = /[\w\d]+/g;
    if (!nameRe.exec(formValues.name)) {
      errs.push({field: 'name', message: 'Name must contain numbers or letters.'});
    }
    if (formValues.reward_type !== 'free_shipping' && formValues.value <= 0) {
      errs.push({field: 'value', message: 'Value must be greater than 0.'});
    }
    if (formValues.items.restriction_type !== 'all_orders' && formValues.items.ids.length === 0) {
      errs.push({
        field: 'restriction_type', 
        message: `You must select at least one ${formValues.items.restriction_type === 'collections' ? 'collection' : 'product'}.`
      });
    }
    if (formValues.usage_limit && (formValues.usage_limit_value <= 0 || !parseInt(formValues.usage_limit_value))) {
      errs.push({field: 'usage_limit_value', message: 'Must be a positive whole number.'});
    }
    if (formValues.expires && (formValues.days_available <= 0 || !parseInt(formValues.days_available))) {
      errs.push({field: 'expires', message: 'Must be a positive whole number.'});
    }
    if (formValues.minimum && (formValues.minimum_requirement <= 0 || !parseFloat(formValues.minimum_requirement))) {
      errs.push({field: 'minimum', message: 'Must be a positive number.'});
    }
    if (formValues.shipping_threshold && (formValues.shipping_threshold_value <= 0 || !parseFloat(formValues.shipping_threshold_value))) {
      errs.push({field: 'shipping_threshold', message: 'Must be a positive number.'});
    }
    if (formValues.cost <= 0 || !parseInt(formValues.cost)) {
      errs.push({field: 'cost', message: 'Must be a positive whole number.'});
    }
    // console.log(nameRe.exec(formValues.name));
    return errs;
  }
  const toggleStatus = () => {
    setFormValues({...formValues, status: formValues.status === 'active' ? 'inactive' : 'active'});
  }

  const submit = async () => {
    // validate formValues
    let errs = await validate();
    // set all buttons to disabled
    if (errs.length === 0) {
      toggleDisable();
      // console.log(giveaway, i, formValues);
      // disable form editing
      axios.put('https://api.amplifyloyalty.com/app/stores/redemptions', 
        formValues,
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          // Add coupon to state
          let newProgram = {...program};
          if (formValues._id) { // submission was edit
            let editedCouponIndex = newProgram.rewards.findIndex(cp => cp._id === formValues._id);
            newProgram.rewards[editedCouponIndex] = res.data;
          } else {  
            newProgram = {...program, rewards: [...program.rewards, res.data]}
          }
          setProgram(newProgram);
          setCreatingCoupon(false);
        }
      }).catch(err => {
        // Re-enable form editing
        toggleDisable();
        responseHandler(err);
      })
      
    } else {
      setErrors(errs);
    }
  }

  return (
    <div className="flex-popup-overlay">
      {searchPreview && 
        <SimpleSearchPreview 
          close={setSearchPreview}
          heading={formValues.items.restriction_type === 'collections' ? 'Add collections' : 'Add products'}
          formValues={[formValues,setFormValues]}
          endpoint={formValues.items.restriction_type === 'collections' ? 'collections' : 'products'}
        />
      }
      {!searchPreview && 
        <section className="redemption-form-container">
          {searchPreview && <div className="redemption-form-searching-overlay"></div>}
          <div className="redemption-form">
            <div className="redemption-form__heading">
              <h3 className='redemption-form__title'>{formValues.name || `${coupon === true ? 'New' : 'Edit'} Reward`}</h3>
              <div className="redemption-form__editing">
                {coupon && coupon.status !== 'archived' && 
                  <>
                    {coupon !== true && <div className="redemption-form__button"><i className="fa-solid fa-trash redemption-form__delete" onClick={() => archiveRedemption()}></i></div>}
                    <label className="redemption-form__toggle-status switch">
                      <input className="switch-input" type="checkbox" name="status" value={(formValues.status === 'active' ? true : false)} onChange={() => {toggleStatus();}} />
                      <span className="slider"></span>
                    </label>
                  </>
                }
              </div>
            </div>
            <div className="redemption-form__fields">
              {notIntegrated && 
                <div className="redemption-form__integration-notice">You must integrate your Shopify account to create coupons.</div>
              }
              <div className="redemption-form__field">
                <label htmlFor="name">Name</label>
                <div className="redemption-form__input-container">
                  <input className="redemption-form__input" name="name" value={formValues.name} onChange={(e) => setFormValues({...formValues, name: e.target.value })}/>
                </div>
                {errors && errors.filter(e => e.field === 'name').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'name')[0].message}</div>
                }
              </div>
              <div className="redemption-form__field">
                <label htmlFor="type">Type</label>
                <div className="redemption-form__input-container">
                  <select name="reward_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.reward_type} onChange={(e) => setFormValues({...formValues, reward_type: e.target.value, items: { restriction_type: "all_orders", ids: []}})}>
                    <option value="currency_off">Amount off</option>
                    <option value="percent_off">Percent off</option>
                    <option value="free_shipping">Free Shipping</option>
                  </select>
                </div>
              </div>
              <div className="redemption-form__field">
                <label htmlFor="cost">Cost</label>
                <div className="redemption-form__input-container">
                  <input type="number" className="redemption-form__input redemption-form__input--points" name="cost" value={formValues.cost} onChange={(e) => setFormValues({...formValues, cost: parseInt(e.target.value)})}/>
                  <div className="redemption-form__input-identifier">points</div>
                </div>
                {errors && errors.filter(e => e.field === 'cost').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'cost')[0].message}</div>
                }
              </div>
              {valueSign && 
                <div className="redemption-form__extra-fields">
                  <div className="redemption-form__field">
                    <label htmlFor="value">Value</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">{valueSign}</div>
                      <input className="redemption-form__input redemption-form__input--value" name="value" type="number" value={formValues.value} onChange={(e) => setFormValues({...formValues, value: parseFloat(e.target.value)})}/>
                    </div>
                    {errors && errors.filter(e => e.field === 'value').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'value')[0].message}</div>
                    }
                  </div>
                  {/* <FormError className="vip-form__error-message" inputName="value" /> */}
                </div>
              }
              {(valueSign === "$") && 
                <div className="redemption-form__field">
                  <div>Customer's % back</div>
                  <div className="redemption-form__percent-back">
                    {((formValues.value / (formValues.cost / program.earnings.purchase.points) * 100) || 0).toFixed(2)}%
                  </div>
                </div>
              }
              {formValues.reward_type !== 'free_shipping' &&
                <div className="redemption-form__field">
                  <label htmlFor="restriction_type">Applies to</label>
                  <div className="redemption-form__input-container">
                    <select name="restriction_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.items.restriction_type} onChange={(e) => setFormValues({...formValues, items: { restriction_type: e.target.value, ids: []}})}>
                      <option value="all_orders">All orders</option>
                      <option value="collections">Specific collections</option>
                      <option value="products">Specific products</option>
                    </select>
                  </div>
                  {errors && errors.filter(e => e.field === 'restriction_type').length > 0 && 
                    <div className="redemption-form__error">{errors.filter(e => e.field === 'restriction_type')[0].message}</div>
                  }
                </div>
              }
              {formValues.items.restriction_type !== 'all_orders' && 
                <div className="extra-fields">
                  {coupon.status !== 'archived' && <div className="vip-form__add-bonus" onClick={() => setSearchPreview(true)}>+ {formValues.items.restriction_type === 'collections' ? "Collection" : "Products"}</div>}
                  <SimplePicksDisplay formValues={formValues} setFormValues={setFormValues} disabled={formValues.status === 'archived' ? true : false}/>
                </div>
              }
              {formValues.reward_type === 'free_shipping' &&
                <>
                  <div className="redemption-form__field redemption-form__field--left">
                    <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="shipping_threshold" value={formValues.shipping_threshold} checked={formValues.shipping_threshold} onChange={(e) => setFormValues({...formValues, shipping_threshold: e.target.checked})}/>
                    <label className="redemption-form__label--small" htmlFor="shipping_threshold">Max shipping value</label>
                  </div>
                  {formValues.shipping_threshold && 
                    <div className="extra-fields">
                      <div className="redemption-form__field">
                        <label className='redemption-form__sub-label' htmlFor="shipping_threshold_value">Up to</label>
                        <div className="redemption-form__input-container">
                          <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                          <input className="redemption-form__input redemption-form__input--value" name="shipping_threshold_value" type="number" value={formValues.shipping_threshold_value} onChange={(e) => setFormValues({...formValues, shipping_threshold_value: parseFloat(e.target.value)})}/>
                        </div>
                        {errors && errors.filter(e => e.field === 'shipping_threshold').length > 0 && 
                          <div className="redemption-form__error">{errors.filter(e => e.field === 'shipping_threshold')[0].message}</div>
                        }
                      </div>
                      <div className="error-message">
                      </div>
                    </div>
                  }
                </>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="usage_limit" value={formValues.usage_limit} checked={formValues.usage_limit} onChange={(e) => setFormValues({...formValues, usage_limit: e.target.checked})}/>
                <label className="redemption-form__label--small" htmlFor="usage_limit">Usage limit</label>
              </div>
              {formValues.usage_limit && 
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="usage_limit_value">Can be used</label>
                    <div className="redemption-form__input-container">
                      <input className="redemption-form__input redemption-form__input--points" name="usage_limit_value" type="number" value={formValues.usage_limit_value} onChange={(e) => setFormValues({...formValues, usage_limit_value: parseInt(e.target.value)})} />
                      <div className="redemption-form__input-identifier">times</div>
                    </div>
                    {errors && errors.filter(e => e.field === 'usage_limit_value').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'usage_limit_value')[0].message}</div>
                    }
                  </div>
                </div>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="expires" value={formValues.expires} checked={formValues.expires} onChange={(e) => setFormValues({...formValues, expires: e.target.checked})}/>
                <label className="redemption-form__label--small" htmlFor="expires">Set expiration</label>
              </div>
              {formValues.expires && 
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="days_available">Expires after</label>
                    <div className="redemption-form__input-container">
                      <input className="redemption-form__input redemption-form__input--points" name="days_available" type="number" value={formValues.days_available} onChange={(e) => setFormValues({...formValues, days_available: parseInt(e.target.value)})}/>
                      <div className="redemption-form__input-identifier">days</div>
                    </div>
                    {errors && errors.filter(e => e.field === 'expires').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'expires')[0].message}</div>
                    }
                  </div>
                </div>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="minimum" value={formValues.minimum} checked={formValues.minimum} onChange={(e) => setFormValues({...formValues, minimum: e.target.checked })}/>
                <label className="redemption-form__label--small" htmlFor="minimum">Minimum order value</label>
              </div>
              {formValues.minimum &&
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="minimum_value">Subtotal at least</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                      <input className="redemption-form__input redemption-form__input--value" name="minimum_requirement" type="number" value={formValues.minimum_requirement} onChange={(e) => setFormValues({...formValues, minimum_requirement: parseFloat(e.target.value)})}/>
                    </div>
                    {errors && errors.filter(e => e.field === 'minimum').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'minimum')[0].message}</div>
                    }
                  </div>
                </div>
              }
            </div>
            <div className="redemption-form__field redemption-form__button-container">
              <button className="redemption-form__cancel-button btn" type="button" onClick={() => {setCreatingCoupon(false)}}>{coupon.status === 'archived' ? 'Exit' : 'Cancel'}</button>
              {coupon.status !== 'archived' && !notIntegrated && <button className="redemption-form__submit-button btn gradient" type="submit" onClick={submit}>{coupon === true ? 'Create' : 'Save'}</button>}
            </div>
          </div>
        </section>
      }
    </div>
  )
}
export const PutRedemptionForm = (props) => {
  const {logout} = useContext(AuthContext);
  const [formValues, setFormValues] = props.formValues;
  const [percent, setPercent] = useState(0);
  const [valueSign, setValueSign] = useState('$');
  const [expires, setExpires] = useState(false); // Determines to show form info
  const [minimum, setMinimum] = useState(false); // Determines to show form info
  const [usageLimit, setUsageLimit] = useState(false);
  const [previousFormValues, setPreviousFormValues] = useState(false);
  const [validation, setValidation] = useState(false);
  const [searchPreview, setSearchPreview] = useState(false);
  const [productIds, setProductIds] = useState(formValues.restriction_type === 'products' ? formValues.ids : []);
  const [collectionIds, setCollectionIds] = useState(formValues.restriction_type === 'collections' ? formValues.ids : []);
  const [restriction, setRestriction] = useState(false);
  const [couponType, setCouponType] = props.couponType // Determines state of form
  const { redemption, setRedemption, program, setProgram, isEditing, setIsEditing, paid } = props;

  // Create a pre-popup that confirms free or paid
  useEffect(() => {
    if (couponType) {
      if (!isEditing) {
        let today = new Date();
        setFormValues({
          name: '',
          status: 'active',
          type: "currency_off",
          free: !paid,
          hour: "12",
          min: "00",
          daytime: "am",
          end_date: `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`,
          cost: 0,
          value: 0,
          expires: false,
          days_available: "",
          minimum: false,
          minimum_value: "",
          usage_limit: true,
          usage_limit_value: 1,
          restriction_type: 'all_orders'
          // search: ""
        });
        setPreviousFormValues(formValues);
      }
      if (couponType === 'paid') {
        setValidation(Yup.object({
          type: Yup.string(),
          value: Yup.number('Must be a number').integer('Must be a whole number').moreThan(0, 'Must be greater than 0').required('Required'),
          cost: Yup.number('Must be a number').integer('Must be a whole number').moreThan(0, 'Must be greater than 0').required('Required'),
          days_available: Yup.number().integer('Must be a whole number').moreThan(0, 'Must be greater than 0'),
          minimum_value: Yup.number().integer('Must be a whole number').moreThan(0, 'Must be greater than 0')
        }))
      } else {
        setValidation(Yup.object({
          type: Yup.string(),
          value: Yup.number('Must be a number').integer('Must be a whole number').moreThan(0, 'Must be greater than 0').required('Required'),
          minimum_value: Yup.number().integer('Must be a whole number').moreThan(0, 'Must be greater than 0')
        }))
      }
    }
  }, [couponType])

  useEffect(() => {
    let typeEditing = document.querySelector('.redemption-form__input--type');
    if (isEditing && typeEditing) {
      // Disable type editing
      typeEditing.disabled = true;
    }
  }, [formValues])

  const putCoupon = (values) => {
    // disable form editing
    toggleDisable();
    // Combine expiration date & time to ends_at field
    let payload = {...values}
    payload.data.free = couponType === 'free' ? true : false;
    if (isEditing) {
      payload.data._id = redemption._id
    }
    if (payload.data.expires && payload.data.free) {
      payload.data.ends_at = new Date(`${payload.data.end_date} ${payload.data.hour}:${payload.data.min} ${payload.data.daytime}`);
    }
    axios.put('https://api.amplifyloyalty.com/app/stores/redemptions', 
      values,
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        // Add coupon to state
        let newProgram = {...program};
        newProgram.rewards = res.data.program.rewards;
        setProgram(newProgram);
        // Close form
        setRedemption(false);
        setIsEditing(false);
        toggleDisable();
        responseHandler(res, "success");
      }
    }).catch(err => {
      // Re-enable form editing
      toggleDisable();
      responseHandler(err);
    })
  }

  const archiveRedemption = async () => {
    if (window.confirm("Are you sure you want to delete this coupon? This can't be undone.")) {
      axios.delete('https://api.amplifyloyalty.com/app/stores/redemptions',
        {
          data: redemption,
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          setFormValues(false);
          setProgram(res.data);
          responseHandler(res, "success");
          setIsEditing(false);
          setRedemption(false);
          setCouponType(false);
        }
      }).catch(err => {
        responseHandler(err);
      })
    }
  }

  const toggleDisable = () => {
    let redemptionInputs = document.querySelectorAll('.redemption-form-container input');
    let redemptionButtons = document.querySelectorAll('.redemption-form__button-container > *');
    [...redemptionInputs, ...redemptionButtons].forEach(target => {
      if (target.getAttribute('name') === 'type' && isEditing) {

      } else {
        target.disabled = target.disabled ? false : true;
      }
    });
  }

  const toggleStatus = () => {
    setFormValues({...formValues, status: formValues.status === 'active' ? 'inactive' : 'active'});
  }

  const closePopups = (bool) => {
    setFormValues(false);
    setRedemption(false);
  }

  const DynamicFields = (props) => {
    const { setPreview } = props;
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      if (previousFormValues.expires === true && values.expires === false) {
        setFieldValue('days_available', '');
      }
      if (previousFormValues.minimum === true && values.minimum === false) {
        setFieldValue('minimum_value', '');
      }
      // Update % back preview
      if (values.cost && values.value) {
        setPreview((values.value / (values.cost / program.earnings.purchase.points) * 100).toFixed(2))
      }
      // Show extra fields unless Free Shipping type
      switch (values.type) {
        case "currency_off":
          setValueSign("$");
          break;
        case "percent_off":
          setValueSign("%");
          break;
        case "free_shipping":
          setValueSign(false);
          break;
        default: 
          setValueSign("$");
      }
      switch (values.restriction_type) {
        case "all_orders":
          setRestriction(false);
          break;
        case "collections":
          setRestriction('collections');
          break;
        case "products":
          setRestriction('products');
          break;
        default: 
          setRestriction(false);
      }
      // Optional Fields
      setExpires(values.expires);
      setMinimum(values.minimum);
      setUsageLimit(values.usage_limit);
      setPreviousFormValues(values);
      // Figure this out in a later feature. Going to have to pull in collection data & display
      // setRestricted(values.restricted);
    }, [values]);
    return null;
  }

  const CouponTypeConfirmation = (props) => {

    return (
        <section className='coupon-type-confirmation'>
          <div className="coupon-type-confirmation__close-wrapper">
            <CloseButton action={closePopups} className='coupon-type-confirmation__close' />
          </div>
          <div className="coupon-type-confirmation__cards">
            <div className="coupon-type-confirmation__card">
              <h3 className="coupon-type-confirmation__heading"><i className="fa-solid fa-money-bill coupon-type-confirmation__icon"></i>Paid</h3>
              <p className="coupon-type-confirmation__description">Coupons customers can only redeem in exchange for points they have earned.</p>
              <button className="coupon-type-confirmation__submit-button btn gradient" onClick={() => {setCouponType('paid')}}>Create</button>
            </div>
            <div className="coupon-type-confirmation__card">
              <h3 className="coupon-type-confirmation__heading"><i className="fa-solid fa-gift coupon-type-confirmation__icon"></i>Free</h3>
              <p className="coupon-type-confirmation__description">Coupons made available to every customer that is logged into their account.</p>
              <button className="coupon-type-confirmation__submit-button btn gradient" onClick={() => {setCouponType('free')}}>Create</button>
            </div>
          </div>
        </section>
    )
  }
  

  return (
    <div className="popup-overlay">
      <section className='redemption-form-container'>
        {!formValues && <CouponTypeConfirmation />}
        {formValues &&
          <>
            <Formik 
              initialValues={formValues}
              validationSchema={validation}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                let restrictionValues;
                if (values.restriction_type === 'collections') {
                  restrictionValues = [...collectionIds];
                }
                if (values.restriction_type === 'products') {
                  restrictionValues = [...productIds];
                }
                putCoupon({data: {...values, restriction_values: restrictionValues}, new: !isEditing});

              }}
            >
              <Form className="redemption-form">
                <div className="redemption-form__heading">
                  <h3 className='redemption-form__title'>{isEditing ? 'Edit' : 'New'} Reward</h3>
                  <div className="redemption-form__editing">
                    <label className="redemption-form__toggle_status switch">
                      <input className="switch-input" type="checkbox" name="status" value={(formValues.status === 'active' ? true : false)} onChange={() => {toggleStatus();}} />
                      <span className="slider"></span>
                    </label>
                    {/* <ToggleStatus /> */}
                    {isEditing && <div className="redemption-form__button"><i className="fa-solid fa-trash redemption-form__delete" onClick={() => archiveRedemption()}></i></div>}
                  </div>
                </div>
                <div className="redemption-form__field">
                  <label htmlFor="name">Name</label>
                  <div className="redemption-form__input-container">
                    <Field className="redemption-form__input" name="name" />
                  </div>
                </div>
                <div className="redemption-form__field">
                  <label htmlFor="type">Type</label>
                  <div className="redemption-form__input-container">
                    <Field as="select" name="type" className="redemption-form__input redemption-form__input--select redemption-form__input--type">
                      <option value="currency_off">Amount off</option>
                      <option value="percent_off">Percent off</option>
                      <option value="free_shipping">Free Shipping</option>
                    </Field>
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage name="type" />
                </div>
                {couponType === 'paid' && 
                  <div className="redemption-form__extra-fields">
                    <div className="redemption-form__field">
                      <label htmlFor="cost">Cost</label>
                      <div className="redemption-form__input-container">
                        <Field className="redemption-form__input redemption-form__input--points" name="cost" type="number" />
                        <div className="redemption-form__input-identifier">points</div>
                      </div>
                    </div>
                    <div className="error-message">
                      <ErrorMessage name="cost" />
                    </div>
                  </div>
                }
                {valueSign &&
                <div className="redemption-form__extra-fields">
                  <div className="redemption-form__field">
                    <label htmlFor="value">Value</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">{valueSign}</div>
                      <Field className="redemption-form__input redemption-form__input--value" name="value" type="number" />
                    </div>
                  </div>
                  <div className="error-message">
                    <ErrorMessage name="value" />
                  </div>
                  {(valueSign === "$" && couponType === 'paid') && 
                    <div className="redemption-form__field">
                      <div>Customer's % back</div>
                      <div className="redemption-form__percent-back">
                        {percent || 0}%
                      </div>
                    </div>
                  }
                </div>
                }
                <div className="redemption-form__field">
                  <label htmlFor="restriction_type">Applies to</label>
                  <div className="redemption-form__input-container">
                    <Field as="select" name="restriction_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type">
                      <option value="all_orders">All orders</option>
                      <option value="collections">Specific collections</option>
                      <option value="products">Specific products</option>
                    </Field>
                  </div>
                </div>
                {restriction && 
                  <div className="extra-fields">
                    <div className="vip-form__add-bonus" onClick={() => setSearchPreview(true)}>+ {restriction === 'collections' ? "Collection" : "Products"}</div>
                    <PicksDisplay 
                      picks={restriction === 'collections' ? collectionIds : productIds} 
                      setPicks={restriction === 'collections' ? setCollectionIds : setProductIds}  />
                  </div>
                }
                <div className="redemption-form__field redemption-form__field--left">
                  <Field className="redemption-form__input redemption-form__input--small" type="checkbox" name="usage_limit" />
                  <label className="redemption-form__label--small" htmlFor="usage_limit">Usage limit</label>
                </div>
                {usageLimit && 
                  <div className="extra-fields">
                    <div className="redemption-form__field">
                      <label className='redemption-form__sub-label' htmlFor="usage_limit_value">Can be used</label>
                      <div className="redemption-form__input-container">
                        <Field className="redemption-form__input redemption-form__input--points" name="usage_limit_value" type="number" />
                        <div className="redemption-form__input-identifier">times</div>
                      </div>
                    </div>
                    <FormError className="vip-form__error-message" inputName="usage_limit_value" />
                  </div>
                }
                <div className="redemption-form__field redemption-form__field--left">
                  <Field className="redemption-form__input redemption-form__input--small" type="checkbox" name="expires" />
                  <label className="redemption-form__label--small" htmlFor="expires">Set Expiration</label>
                </div>
                {expires &&
                  <div className="extra-fields">
                      {couponType === 'paid' &&
                        <div className="redemption-form__field">
                          <label className='redemption-form__sub-label' htmlFor="days_available">Expires after</label>
                          <div className="redemption-form__input-container">
                            <Field className="redemption-form__input redemption-form__input--points" name="days_available" type="number" />
                            <div className="redemption-form__input-identifier">days</div>
                          </div>
                        </div>
                      }
                      {couponType === 'free' && 
                      <div className="redemption-form__field-container">
                        <div className="redemption-form__field">
                          <label className="redemption-form__sub-label" htmlFor="days_available">Expires</label>
                          <div className="redemption-form__input-container redemption-form__input-container--datetime">
                            <DatePicker containerClass="redemption-form__input-container" inputClass="redemption-form__input" dateName="end_date" />
                            <TimePicker className="redemption-form__input-container" />
                          </div>
                        </div>
                        <div className="error-message">
                          <ErrorMessage name="end_date" />
                        </div>
                      </div>
                      }
                    <div className="error-message">
                      <ErrorMessage name="days_available" />
                    </div>
                  </div>
                }
                <div className="redemption-form__field redemption-form__field--left">
                  <Field className="redemption-form__input redemption-form__input--small" type="checkbox" name="minimum" />
                  <label className="redemption-form__label--small" htmlFor="minimum">Minimum order value</label>
                </div>
                {minimum &&
                  <div className="extra-fields">
                    <div className="redemption-form__field">
                      <label className='redemption-form__sub-label' htmlFor="minimum_value">Subtotal at least</label>
                      <div className="redemption-form__input-container">
                        <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                        <Field className="redemption-form__input redemption-form__input--value" name="minimum_value" type="number" />
                      </div>
                    </div>
                    <div className="error-message">
                      <ErrorMessage name="value" />
                    </div>
                  </div>
                }
                <div className="redemption-form__field redemption-form__button-container">
                  <button className="redemption-form__cancel-button btn" onClick={() => {setRedemption(false); setIsEditing(false); setCouponType(false); setFormValues(false)}}>Cancel</button>
                  <button className="redemption-form__submit-button btn gradient" type="submit">Save</button>
                </div>
                <DynamicFields setPreview={setPercent} />
              </Form>
            </Formik>
            {searchPreview && 
              <SearchPreview 
                close={setSearchPreview}
                heading={restriction === 'collections' ? 'Add collections' : 'Add products'}
                picks={restriction === 'collections' ? [collectionIds, setCollectionIds] : [productIds, setProductIds]}
                endpoint={restriction === 'collections' ? 'collections' : 'products'}
              />
            }
          </>
        }
      </section>
    </div>
  )
}

export const RedemptionEditing = (props) => {
  const {className, coupon, setRedemption, setIsEditing, setFormValues, cKey } = props;

  const editRedemption = () => {
    setRedemption(coupon);
    setIsEditing(true);
    let today = new Date();
    let endDate = coupon.ends_at ? new Date(coupon.ends_at) : false;
    setFormValues({
      name: coupon.name,
      status: coupon.status,
      type: coupon.reward_type,
      free: coupon.free,
      hour: endDate ? `${endDate.getHours()+1}` : "12",
      min: endDate ? endDate.getMinutes() < 10 ? `0${endDate.getMinutes()}` : `${endDate.getMinutes()}` : "00",
      daytime: endDate ? endDate.getHours() > 11 ? "PM" : "AM" : "PM",
      end_date: endDate ? `${endDate.getMonth()+1}/${endDate.getDate()}/${endDate.getFullYear()}` : `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`,
      cost: coupon.cost,
      value: coupon.value,
      expires: endDate || coupon.days_available ? true : false,
      days_available: coupon.days_available ? coupon.days_available : "",
      minimum: coupon.minimum_requirement ? true : false,
      minimum_value: coupon.minimum_requirement ? coupon.minimum_requirement : "",
      usage_limit: coupon.usage_limit,
      usage_limit_value: coupon.usage_limit ? coupon.usage_limit_value : 1,
      restriction_type: coupon.items.restriction_type ? coupon.items.restriction_type : 'all_orders',
      ids: coupon.items.restriction_type !== "all_orders" ? coupon.items.ids : false
    });
  }

  return (
    <div className={className}>
      <i className="fa-solid fa-pen-to-square" onClick={() => {editRedemption()}}></i>
    </div>
  )
}

export const RedemptionsTable = (props) => {
  const [program, setProgram] = props.program;
  const [couponType, setCouponType ] = props.couponType;
  const { setRedemption, setIsEditing, setFormValues } = props;
  const [sort, setSort] = useState(false);
  const [couponState, setCouponState] = useState('active');

  const sortTable = (key) => {
    // setSort to key and direction
    if (key !== sort.key || (key === sort.key && sort.sort === 'asc')) {
      setProgram({...program, rewards: program.rewards.sort((a,b) => b[key]-a[key])});
      setSort({
        key: key, 
        sort: 'desc'
      });
    } else {
      setProgram({...program, rewards: program.rewards.sort((a,b) => a[key]-b[key])});
      setSort({
        key: key, 
        sort: 'asc'
      });
    }
  }

  const switchTabs = (event) => {
    document.querySelector('.redemptions-table__type--active').classList.remove('redemptions-table__type--active');
    event.target.classList.add('redemptions-table__type--active');
  }

  const CouponTables = () => {
    return(
      <section className='redemptions-table'>
        <div className="redemptions-table__content-wrapper">
          <div className="redemptions-table__static-column">
            <div className="redemptions-table__header redemptions-table__header--static">
              <div className="redemptions-table__editing--header"></div>
              <div className="redemptions-table__heading redemptions-table__name-header redemptions-table__heading--sortable" onClick={(e) => sortTable('name')}>
                <div className="redemptions-table__sorting-display">
                  Name <i className={`fa-solid fa-arrow-down redemptions-table__cell-sorting${sort.key === 'name' ? ' redemptions-table__cell-sorting--active' : ''}${sort.key === 'name' && sort.sort === 'asc' ? ' redemptions-table__cell-sorting--reverse' : ''}`}></i>
                </div>
                <i className="fa-solid fa-caret-down redemptions-table__sorting-caret"></i>
              </div>
            </div>
            <div className="redemptions-table__body">
            {program && program.rewards.length > 0 &&
              program.rewards.map((coupon,i) => {
                if (coupon.status === couponState) {
                  return (
                    <div key={`coupon-${i}`} className={`redemptions-table__row${coupon.status === 'inactive' || coupon.status === 'archived' ? ' redemptions-table__row--inactive' : ''}`}>
                      <RedemptionEditing 
                        className={`redemptions-table__editing redemptions-table__editing--${i}`} 
                        program={program} 
                        setProgram={setProgram} 
                        coupon={coupon} 
                        setRedemption={setRedemption} 
                        setIsEditing={setIsEditing}
                        couponType={[couponType, setCouponType]}
                        setFormValues={setFormValues}
                        cKey={`redemptions-table__editing--${i}`}
                      />
                      <div className="redemptions-table__cell redemptions-table__name">{coupon.name ? coupon.name : '--'}</div>
                    </div>
                  )
                } else return false
              })
            }
            </div>
          </div>
          <div className="redemptions-table__scrollable">
            <div className="redemptions-table__header redemptions-table__header--scrollable">
                <div className="redemptions-table__heading redemptions-table__heading--sortable" onClick={(e) => sortTable('cost')}>
                  <div className="redemptions-table__sorting-display">
                    Cost (points)<i className={`fa-solid fa-arrow-down redemptions-table__cell-sorting${sort.key === 'cost' ? ' redemptions-table__cell-sorting--active' : ''}${sort.key === 'cost' && sort.sort === 'asc' ? ' redemptions-table__cell-sorting--reverse' : ''}`}></i>
                  </div>
                  <i className="fa-solid fa-caret-down redemptions-table__sorting-caret"></i>
                </div>
              <div className="redemptions-table__heading">Value</div>
              <div className="redemptions-table__heading">% Back</div>
              <div className="redemptions-table__heading" onClick={(e) => sortTable('redeemed')}>
                <div className="redemptions-table__sorting-display">
                  Redeemed <i className={`fa-solid fa-arrow-down redemptions-table__cell-sorting${sort.key === 'redeemed' ? ' redemptions-table__cell-sorting--active' : ''}${sort.key === 'redeemed' && sort.sort === 'asc' ? ' redemptions-table__cell-sorting--reverse' : ''}`}></i>
                </div>
                <i className="fa-solid fa-caret-down redemptions-table__sorting-caret"></i>
              </div>
              <div className="redemptions-table__heading" onClick={(e) => sortTable('date_created')}>
                <div className="redemptions-table__sorting-display">
                  Created <i className={`fa-solid fa-arrow-down redemptions-table__cell-sorting${sort.key === 'date_created' ? ' redemptions-table__cell-sorting--active' : ''}${sort.key === 'date_created' && sort.sort === 'asc' ? ' redemptions-table__cell-sorting--reverse' : ''}`}></i>
                </div>
                <i className="fa-solid fa-caret-down redemptions-table__sorting-caret"></i>
              </div>
            </div>
            <div className="redemptions-table__body">
            {program && program.rewards.length > 0 &&
              program.rewards.map((coupon,i) => {
                if (coupon.status === couponState) {
                  let couponValue;
                  let percentBack = "--";
                  switch (coupon.reward_type) {
                    case "currency_off":
                      couponValue = `$${coupon.value}`;
                      percentBack = `${(coupon.value / (coupon.cost / program.earnings.purchase.points) * 100).toFixed(2)}%`
                      break;
                    case "percent_off":
                      couponValue = `${coupon.value}%`;
                      break;
                    case "free_shipping":
                      couponValue = "Free Shipping";
                      break;
                    default:
                      console.log('No match found.');
                  }
                  return (
                    <div key={`coupon-${i}`} className={`redemptions-table__row${coupon.status === 'inactive' || coupon.status === 'archived' ? ' redemptions-table__row--inactive' : ''}`}>
                      <div className="redemptions-table__cell-wrapper"><div className='redemptions-table__cell'>{coupon.cost}</div></div>
                      <div className="redemptions-table__cell-wrapper"><div className='redemptions-table__cell'>{couponValue}</div></div>
                      <div className="redemptions-table__cell-wrapper"><div className='redemptions-table__cell'>{percentBack}</div></div>
                      <div className="redemptions-table__cell-wrapper"><div className="redemptions-table__cell">{coupon.redeemed || 0}</div></div>
                      <div className="redemptions-table__cell-wrapper"><div className='redemptions-table__cell'>{new Date(coupon.date_created).toLocaleDateString()}</div></div>
                    </div>
                  )
                } else return false;
              })
            }
          </div>
          </div>
        </div>
        {program && program.rewards.filter(coup => coup.status === couponState).length === 0 &&
          <div className="redemptions-table__empty">
            <div className="redemptions-table__empty-warning">No redemptions to show.</div>
          </div>
        }
        {!program && <Loading className="redemptions-table__loading" />}
      </section>
    )
  }

  return (
    <section className='redemptions-options-container centralize'>
      <div className="redemptions-options-content">
        <div className="redemptions-options-header">
          <div className="redemptions-options__heading-wrapper">
            <h4 className="redemptions-options__title">Ways to Redeem</h4>
            <p className="redemptions-options__description">Rewards for customers to redeem for points.</p>
          </div>
          <button className="btn gradient redemptions-options__create" onClick={() => {setRedemption(true)}}>Create reward</button>
        </div>
        <div className="redemptions-table__coupon-types">
          <div className="redemptions-table__type-container">
            <div className="redemptions-table__type redemptions-table__type--active" onClick={(event) => {setCouponState('active'); switchTabs(event); setSort(false)}}>Active</div>
          </div>
          <div className="redemptions-table__type-container">
            <div className="redemptions-table__type" onClick={(event) => {setCouponState('inactive'); switchTabs(event); setSort(false)}}>Disabled</div>
          </div>
          <div className="redemptions-table__type-container">
            <div className="redemptions-table__type" onClick={(event) => {setCouponState('archived'); switchTabs(event); setSort(false)}}>Archived</div>
          </div>
        </div>
        <CouponTables />
      </div>
    </section>
  )
}