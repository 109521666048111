import { CallToAction } from "../../components/website/Utility";
import { VIPDescription, VIPDisplay, VIPHero, VIPTiers } from "../../components/WebsiteComponents";
import '../../assets/css/website/component-vip-hero.css';
import '../../assets/css/website/component-vip-description.css';
import '../../assets/css/website/component-vip-tiers.css';
import '../../assets/css/website/component-vip-display.css';

export default function WVIP() {
  return (
    <>
      <VIPHero 
        header="VIP PROGRAM"
        body="Tiers of added perks and bonuses for your most loyal customers."
        img="https://amplify-loyalty-website.s3.amazonaws.com/1---Avatar+(1).png"
      />
      <VIPDescription 
        header="MORE INCENTIVE, MORE LOYALTY"
        body="Reward customers customers in any way you see fit to keep them coming back instead of going to your competitors."
        img="https://amplify-loyalty-website.s3.amazonaws.com/2-Set+(1).png"
        background="https://amplify-loyalty-website.s3.amazonaws.com/4-background+(1).png"
      />
      <VIPTiers 
        header="FIVE CUSTOMIZABLE TIERS"
        body="Create the tiers and let your customers earn their way to the top."
        img="https://amplify-loyalty-website.s3.amazonaws.com/2-Tiers+(1).png"
      />
      <VIPDisplay 
        header="PERMANENT PERKS + SINGLE USE BONUSES"
        body="Create permanent perks per tier such as a percentage point increase on earnings and extra incentives like 10% off coupons or Free Shipping."
        img="https://amplify-loyalty-website.s3.amazonaws.com/3-I-MAC+(1).png"
        popup="https://amplify-loyalty-website.s3.amazonaws.com/3-top-tools+(1).png"
      />
      <CallToAction />
    </>
  )
}