import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
const jwt = require('jsonwebtoken');


export default function Navigation() {
  const {authed, logout} = useContext(AuthContext);
  const [store, setStore] = useState(false);
  const [user, setUser] = useState(false);
  const [choosingStore, setChoosingStore] = useState(true);
  let location = useLocation();

  useEffect(() => {
    if (authed) {
      const tkn = JSON.parse(localStorage.getItem('_id'));
      setStore(tkn);
      setUser(jwt.decode(tkn.accessToken))
    }
    // set a eventlistern for clicking on the navigation opening
      // when open, remove eventlistener and create new one for closing
      // on close, remove eventlistener and reenable opening
  }, [])

  const changeStore = (str) => {
    console.log(str);
    axios.post("https://api.amplifyloyalty.com/app/auth/switch",
      str,
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      console.log(res);
    }).catch(console.log);
  }



  return (
    <>
      {authed && location.pathname.includes('/app') && 
        <nav className="main-navigation">
          <div className="navigation">
            <a href='/app/dashboard'className="navigation__link-wrapper">
              <i className={`navigation__icon ${location.pathname.includes('/app/dashboard') ? "fa-solid fa-chart-pie navigation__icon--active" : "fa-solid fa-chart-pie"}`}></i>
              <div className={`navigation__link${location.pathname === '/app/dashboard' ? " navigation__link--active" : ""}`}>Dashboard</div>
            </a>
            <a href='/app/customers' className="navigation__link-wrapper">
              <i className={`navigation__icon ${location.pathname.includes('/app/customers') ? "fa-solid fa-users navigation__icon--active" : "fa-solid fa-users"}`}></i>
              <div className={`navigation__link${location.pathname === '/app/customers' ? " navigation__link--active" : ""}`}>Customers</div>
            </a>
            <a href='/app/program' className="navigation__link-wrapper">
              <i className={`navigation__icon fa-solid fa-handshake ${location.pathname === '/app/program' ? "navigation__icon--active" : ""}`}></i>
              <div className={`navigation__link${location.pathname === '/app/program' ? " navigation__link--active" : ""}`}>Program</div>
            </a>
            <a href='/app/vip' className="navigation__link-wrapper">
              <i className={`navigation__icon fa-solid fa-crown ${location.pathname.includes('/app/vip') ? "navigation__icon--active" : ""}`}></i>
              <div className={`navigation__link${location.pathname === '/app/vip' ? " navigation__link--active" : ""}`}>VIP</div>
            </a>
            {/* <div className="navigation__navigation__link-wrapper">
              <i className={`navigation__icon fa-solid fa-envelope ${location.pathname.includes('/giveaways') ? "navigation__icon--active" : ""}`}></i>
              <a className={`navigation__link${location.pathname === '/amplifiers' ? " navigation__link--active" : ""}`} href='/giveaways'>Emails</a>
            </div> */}
            <a href='/app/promotions' className="navigation__link-wrapper">
              <i className={`navigation__icon fa-solid fa-bullhorn ${location.pathname.includes('/app/promotions') ? "navigation__icon--active" : ""}`}></i>
              <div className={`navigation__link${location.pathname === '/app/promotions' ? " navigation__link--active" : ""}`}>Promotions</div>
            </a>
            {/* <div className="navigation__link-wrapper">
              <i className={`navigation__icon fa-solid fa-wifi ${location.pathname.includes('/amplifiers') ? "navigation__icon--active" : ""}`}></i>
              <a className={`navigation__link${location.pathname === '/amplifiers' ? " navigation__link--active" : ""}`} href='/amplifiers'>Amplifiers</a>
            </div> */}
            <a href='/app/giveaways' className="navigation__link-wrapper">
              <i className={`navigation__icon fa-solid fa-gift ${location.pathname.includes('/app/giveaways') ? "navigation__icon--active" : ""}`}></i>
              <div className={`navigation__link${location.pathname === '/app/amplifiers' ? " navigation__link--active" : ""}`}>Giveaways</div>
            </a>
            <a href='/app/integrations' className="navigation__link-wrapper">
              <i className={`navigation__icon fa-solid fa-layer-group ${location.pathname.includes('/app/integrations') ? "navigation__icon--active" : ""}`}></i>
              <div className={`navigation__link${location.pathname === '/app/integrations' ? " navigation__link--active" : ""}`}>Integrations</div>
            </a>
            <a href="/app/settings" className="navigation__link-wrapper">
              <i className={`navigation__icon ${location.pathname.includes('/app/settings') ? "fa-solid fa-gear navigation__icon--active" : "fa-solid fa-gear"}`}></i>
              <div className={`navigation__link${location.pathname === '/app/settings' ? " navigation__link--active" : ""}`}>Settings</div>
            </a>
          </div>
          {store && 
            <div className="main-navigation__store">
              <div className="main-navigation__info">
                <div className="main-navigation__profile">{store.name.slice(0,1).toUpperCase()}</div>
              </div>
              <div className="main-navigation__store-name">{store.name.length < 12 && store.name}{store.name.length > 11 && `${store.name.slice(0,12)}...`}</div>
              {/* {choosingStore && 
                <div className="main-navigation__stores">
                  <div className="main-navigation__email">{user.email}</div>
                  {store.stores.filter(str => str.name !== store.name).map(str => 
                    <div className="main-navigation__extra-store" onClick={() => changeStore(str)}>{str.name.length > 20 ? `${str.name.slice(0,20)}...` : str.name}</div>
                  )}
                </div>  
              } */}
            </div>
            
            // <div className="main-navigation__info">  
            //   <div className="main-navigation__profile">{store.slice(0,1).toUpperCase()}</div>
            // </div>
          }
        </nav>
      }
    </>
  )
}