import {ReactComponent as Hourglass} from '../../assets/images/icons/hourglass.svg';
import { CallToAction } from '../../components/website/Utility';
import '../../assets/css/website/component-promotions-hero.css';
import '../../assets/css/website/component-promotions-points.css';
import '../../assets/css/website/component-promotions-amplifiers.css';
import '../../assets/css/website/component-promotions-schedule.css';
import { PromotionsAmplifiers, PromotionsHero, PromotionsPoints, PromotionsSchedule } from '../../components/WebsiteComponents';
export default function WebPromotions() {
  return (
    <>
      <PromotionsHero 
        heading="PROMOTIONS"
        description="Create and schedule timed Promotions that increase the amount of points a customer can earn for a given amount of time."
        img="https://amplify-loyalty-website.s3.amazonaws.com/2-Reddem-Chips+(1).png"
      />
      <PromotionsPoints 
        heading="POINTS MULTIPLIER"
        body="Create marketing campaigns that increase the amount of points that can be earned on purchases for a certain amount of time. Make it applicable to your entire store, or select a few products or collections that qualify."
      />
      <PromotionsAmplifiers 
        heading="AMPLIFIERS"
        body="Activate marketing tools that are an extension of your loyalty program that communicate to customers at 
          all stages of the marketing cycle. When your promotion is live, these tools will dynamically update for the customer."
        img="https://amplify-loyalty-website.s3.amazonaws.com/Promotions+Amplifiers.png"
      />
      <PromotionsSchedule 
        heading="SCHEDULE"
        body="Set your Promotion on a schedule and let Amplify Loyalty do the rest. All Promotion settings will update your 
          base settings for your program and when the Promotion is scheduled to end, everything will return to normal without you ever having to change it back."
        img="https://amplify-loyalty-website.s3.amazonaws.com/Promotions+Scheduling.png"
      />
      <CallToAction />
    </>
  )
}