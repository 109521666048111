import React from 'react';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { responseHandler } from '../components/utility/Utility';

export default function Settings() {
    const {logout} = useContext(AuthContext);
    const [formValues, setFormValues] = useState({
        name: "",
        timezone: ""
    });
    const [errors, setErrors] = useState({
        name: null,
        timezone: null
    });

    useEffect(() => {
        const toDisable = [...document.querySelectorAll('input'), document.querySelector('.settings__save')];
        toDisable.forEach(el => el.disabled = true);
        axios.get('https://api.amplifyloyalty.com/app/stores/settings',
            {
              params: {
                jwt: localStorage.getItem('_id')
              }
            }).then(res => {
              if (res.data.name === 'BadRequestError') {
                logout();
              } else {
                console.log(res);
                setFormValues({...res.data});
              }
            toDisable.forEach(el => el.disabled = false);          
            }).catch(err => {
              console.log(err);
            })
    }, [])

    const timezones = [
        { value: 'Pacific/Midway', label: 'UTC-11:00 - Pacific/Midway' },
        { value: 'America/Adak', label: 'UTC-10:00 - America/Adak' },
        { value: 'Pacific/Marquesas', label: 'UTC-09:30 - Pacific/Marquesas' },
        { value: 'America/Anchorage', label: 'UTC-09:00 - America/Anchorage' },
        { value: 'America/Los_Angeles', label: 'UTC-08:00 - America/Los Angeles (PST)' },
        { value: 'America/Denver', label: 'UTC-07:00 - America/Denver (MST)' },
        { value: 'America/Chicago', label: 'UTC-06:00 - America/Chicago (CST)' },
        { value: 'America/New_York', label: 'UTC-05:00 - America/New York (EST)' },
        { value: 'America/Caracas', label: 'UTC-04:00 - America/Caracas' },
        { value: 'America/Sao_Paulo', label: 'UTC-03:00 - America/Sao Paulo' },
        { value: 'Atlantic/South_Georgia', label: 'UTC-02:00 - Atlantic/South Georgia' },
        { value: 'Atlantic/Azores', label: 'UTC-01:00 - Atlantic/Azores' },
        { value: 'Europe/London', label: 'UTC+00:00 - Europe/London (GMT)' },
        { value: 'Europe/Berlin', label: 'UTC+01:00 - Europe/Berlin (CET)' },
        { value: 'Europe/Athens', label: 'UTC+02:00 - Europe/Athens (EET)' },
        { value: 'Asia/Baghdad', label: 'UTC+03:00 - Asia/Baghdad' },
        { value: 'Asia/Tehran', label: 'UTC+03:30 - Asia/Tehran' },
        { value: 'Asia/Dubai', label: 'UTC+04:00 - Asia/Dubai' },
        { value: 'Asia/Kabul', label: 'UTC+04:30 - Asia/Kabul' },
        { value: 'Asia/Karachi', label: 'UTC+05:00 - Asia/Karachi' },
        { value: 'Asia/Kolkata', label: 'UTC+05:30 - Asia/Kolkata' },
        { value: 'Asia/Kathmandu', label: 'UTC+05:45 - Asia/Kathmandu' },
        { value: 'Asia/Dhaka', label: 'UTC+06:00 - Asia/Dhaka' },
        { value: 'Asia/Yangon', label: 'UTC+06:30 - Asia/Yangon' },
        { value: 'Asia/Bangkok', label: 'UTC+07:00 - Asia/Bangkok' },
        { value: 'Asia/Shanghai', label: 'UTC+08:00 - Asia/Shanghai' },
        { value: 'Asia/Tokyo', label: 'UTC+09:00 - Asia/Tokyo' },
        { value: 'Australia/Darwin', label: 'UTC+09:30 - Australia/Darwin' },
        { value: 'Australia/Sydney', label: 'UTC+10:00 - Australia/Sydney' },
        { value: 'Pacific/Norfolk', label: 'UTC+11:00 - Pacific/Norfolk' },
        { value: 'Pacific/Auckland', label: 'UTC+12:00 - Pacific/Auckland' },
        { value: 'Pacific/Chatham', label: 'UTC+12:45 - Pacific/Chatham' },
        { value: 'Pacific/Tongatapu', label: 'UTC+13:00 - Pacific/Tongatapu' },
        { value: 'Pacific/Kiritimati', label: 'UTC+14:00 - Pacific/Kiritimati' },
    ];

    const checkErrors = async () => {
        let errors = false;
        for (let field of Object.keys(formValues)) {
            switch (field) {
                case "name":
                    const nameRegxp = /([A-Za-z0-9]+)/g
                    if (!formValues.name.match(nameRegxp)) {
                        setErrors({...errors, name: 'Must include at least 1 number or letter.'})
                        errors = true;
                    };
                    break;
                default:
            }
        }
        return errors;
    }

    const submit = async () => {
        setErrors({
            name: null,
            timezone: null
        });
        const toDisable = [...document.querySelectorAll('input'), document.querySelector('.settings__save')];
        toDisable.forEach(el => el.disabled = true);
        const isErrors = await checkErrors();
        if (!isErrors) {
            axios.put('https://api.amplifyloyalty.com/app/stores/settings',
                {...formValues},
                {
                  params: {
                    jwt: localStorage.getItem('_id')
                  }
                }).then(res => {
                  if (res.data.name === 'BadRequestError') {
                    logout();
                  } else {
                    responseHandler(res, "success", res.data);
                    console.log(res);
                  }
                }).catch(err => {
                    responseHandler(err, "error", "Error: try again later.")
                  console.log(err);
                }).finally(() => {
                    toDisable.forEach(el => el.disabled = false);
                })
        } else toDisable.forEach(el => el.disabled = false);
    }
    
      
    return(
        <div className="main">
            <div className="settings"> 
                {/* <div className="settings__option">
                    <div>Include tax in order totals.</div>
                    <button className="gradient">Toggle</button>
                </div>
                <div className="settings__option">
                    <div>Automatically subtract points on refund.</div>
                    <button className="gradient">Toggle</button>
                </div> */}
                <div className="settings__option">
                    <label htmlFor="name" className="settings__option-label">Store name</label>
                    <input name="name" id="name" className="settings__option-input" value={formValues.name} onChange={(e) => setFormValues({...formValues, name: e.target.value})}/>
                    {errors.name && <div className="settings__option-error">{errors.name}</div>}
                </div>
                <div className="settings__option">
                    <label htmlFor="timezone" className="settings__option-label">Timezone</label>
                    <select name="timezone" className="settings__option-input settings__option-input--select" value={formValues.timezone} onChange={(e) => setFormValues({...formValues, timezone: e.target.value})}>
                        {timezones.map(tmzn => <option key={tmzn.value} value={tmzn.value}>{tmzn.label}</option>)}
                    </select>
                    {errors.timezone && <div className="settings__option-error">{errors.timezone}</div>}
                </div>
                <div className="settings__button-container">
                    <button className="settings__save gradient btn" onClick={submit}>Save</button>
                </div>
            </div>
        </div>
    )
}