
export function Hero(props) {
  const {img, header, body, className} = props;
  return (
    <div className="home-hero">
      <div className="home-hero__overlay"></div>
      <div className="home-hero__img-container">
        <img src={img} alt="" className="home-hero__img"/>
      </div>
      <div className="home-hero__info">
        <h1 className="home-hero__header">{header.split(' ').map((word, i) => <span>{word}{i < header.split(' ').length ? <br /> : ''}</span>)}</h1>
        <p className="home-hero__body">{body}</p>
        <div className="home-hero__buttons">
          {/* <a href="/pricing" className="home-hero__cta btn">Pricing</a> */}
          <a href="/sign-up" className="home-hero__cta btn">Sign Up Free</a>
        </div>
      </div>
    </div>
  )
}

export function Features(props) {
  const {className, header} = props;
  return (
    <div className={className}>
      <h2 className={`${className}__main-header`}>{header}</h2>
      <div className={`${className}__feature-container`}>
        {props.children}
      </div>
    </div>
  )
}

export function FeatureHighlight(props) {
  const {className, icon, header, body, img, cta, link, reverse} = props;
  return (
    <div className={`${className}__feature${reverse ? ` ${className}__feature--reverse` : ''}`}>
      <div className={`${className}__description${reverse ? ` ${className}__description--reverse` : ''}`}>
          <div className={`${className}__icon-container`}>
            <img className={`${className}__icon`} src={icon} alt="Feature Highlight Icon" />
          </div>
        <div className={`${className}__text-container${reverse ? ` ${className}__text-container--reverse` : ''}`}>
          <h3 className={`${className}__header`}>{header}</h3>
          <p className={`${className}__body`}>{body}</p>
          <a className={`${className}__cta btn`} href={link}>{cta}</a>
        </div>
      </div>
      {header !== 'PROMOTIONS' && 
        <div className={`${className}__main-img-container`}>
          <img className={`${className}__main-img`} src={img} alt={`${header} Example`} />
        </div>
      }
      {header === 'PROMOTIONS' &&
        <div className="promotions__feature-content">
          <div className="promotions__feature-multiplier">2x</div>
          <div className="promotions__feature-points">points</div>
        </div>
      }
    </div>
  )
}

export function Callout(props) {
  const {className, img, header, body} = props;

  return (
    <div className={className}>
      <div className={`${className}__img-container`}>
        <img src={img} alt={`${header} Screen`} className={`${className}__img`} />
      </div>
      <div className={`${className}__text`}>
        <h3 className={`${className}__header`}>{header.split(' ').map((word, i) => <span>{word}{i < header.split(' ').length ? <br /> : ''}</span>)}</h3>
        <p className={`${className}__body`}>{body}</p>
      </div>
    </div>
  )
}

export function Integrations(props) {
  const {className, icon, background} = props;

  return (
    <div className={className} style={{backgroundImage: `url("${background}")`}}>
      <div className={`${className}__header-container`}>
        <div className={`${className}__icon-container`}>
          <img src={icon} className={`${className}__icon`} />
        </div>
        <h3 className={`${className}__header`}>INTEGRATE</h3>
        <div className={`${className}__icon-container`}>
          <img src={icon} className={`${className}__icon`} />
        </div>
      </div>
      <div className={`${className}__integrations`}>
        {props.children}
      </div>
    </div>
  )
}

export function IntegrationIcon(props) {
  const {className, img, name} = props;
  return (
    <div className={`${className}__integration`}>
      <div className={`${className}__img-container`}>
        <img src={img} alt={name} className={`${className}__img`} />
      </div>
    </div>
  )
}

export function EarnHero(props) {
  const {header, body, img} = props;
  return (
    <div className="earn-hero">
      <div className="earn-hero__mobile-overlay"></div>
      <div className="earn-hero__text">
        <h1 className="earn-hero__header">{header}</h1>
        <p className="earn-hero__description">{body}</p>
        <a href="/sign-up" className="btn gradient earn-hero__cta">SIGN UP</a>
      </div>
      <div className="earn-hero__img-container">
        <img src={img} alt="" className="earn-hero__img" />
      </div>
    </div>
  )
}

export function Earnings(props) {
  const {img, mImg, header, body} = props;
  return (
    <div className="earnings-section">
      <div className="earnings-section__img-container">
        <img src={img} alt="" className="earnings-section__img" />
        <img src={mImg} alt="" className="earnings-section__img--mobile" />
      </div>
      <div className="earnings-section__text">
        <h3 className="earnings-section__header">{header}</h3>
        <ul className="earnings-section__body">
          {body.map(d => {
            return (
              <li className="earnings-section__wta">{d}</li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export function MarketingStack(props) {
  const {header, body, image} = props;
  return (
    <div className="marketing-stack">
      <h3 className="marketing-stack__header">{header}</h3>
      <p className="marketing-stack__description">{body}</p>
      <img src={image} alt="" className="marketing-stack__img" />
    </div>
  )
}

export function EarningOptions(props) {
  const {header, body, img} = props;
  return (
    <div className="earning-options">
      <h3 className="earning-options__header">{header}</h3>
      <p className="earning-options__description">{body}</p>
      <img src={img} alt="" className="earning-options__img" />
    </div>
  )
}

export function RedeemHero(props) {
  const {header, body, main_img, sub_img} = props;
  return (
    <div className="redeem-hero">
      <div className="redeem-hero__mobile-overlay"></div>
      <div className="redeem-hero__text">
        <h1 className="redeem-hero__header">{header}</h1>
        <p className="redeem-hero__description">{body}</p>
        <a href="/sign-up" className="btn gradient redeem-hero__cta">SIGN UP</a>
      </div>
      <div className="redeem-hero__main-img-container">
        <img src={main_img} alt="" className="redeem-hero__main-img" />
      </div>
    </div>
  )
}

export function RedeemCustomization(props) {
  const {header, body, img, sub_img, background} = props;
  return (
    <div className="redeem-customization" style={{backgroundImage: `url("${background}")`}}>
      <div className="redeem-customization__text">
        <h3 className="redeem-customization__header">{header}</h3>
        <p className="redeem-customization__description">{body}</p>
      </div>
      <div className="redeem-customization__img-container">
        <img src={img} alt="" className="redeem-customization__img" />
        <img src={sub_img} alt="" className="redeem-customization__sub-img" />
      </div>
    </div>
  )
}

export function RedemptionExchange(props) {
  const {header, body, img} = props;
  return (
    <div className="redemption-exchange">
      <div className="redemption-exchange__text">
        <h3 className="redemption-exchange__header">{header}</h3>
        <p className="redemption-exchange__description">{body}</p>
      </div>
      <img src={img} alt="" className="redemption-exchange__img" />
    </div>
  )
}

export function RedemptionCTA(props) {
  const {header, body, lImg, rImg} = props;
  return (
    <div className="redemption-cta">
      <h3 className="redemption-cta__header">{header}</h3>
      <p className="redemption-cta__description">{body}</p>
      <div className="redemption-cta__images">
        <div className="redemption-cta__img-container">
          <img src={lImg} alt="" className="redemption-cta__img-left" />
        </div>
        <div className="redemption-cta__img-container">
          <img src={rImg} alt="" className="redemption-cta__img-right" />
        </div>
      </div>
    </div>
  )
}

export function VIPHero(props) {
  const {header, body, img} = props;
  return (
    <div className="vip-hero">
      <div className="vip-hero__overlay"></div>
      <div className="vip-hero__text">
        <h1 className="vip-hero__header">{header}</h1>
        <p className="vip-hero__description">{body}</p>
        <a href="/sign-up" className="btn gradient giveaway-hero__cta">SIGN UP</a>
      </div>
      <div className="vip-hero__img-container">
        <img src={img} alt="" className="vip-hero__img" />
      </div>
    </div>
  )
}
export function VIPDescription(props) {
  const {header, body, img, background} = props;
  return (
    <div className="vip-description" style={{backgroundImage: `url("${background}")`}}>
      <div className="vip-description__img-container">
        <img src={img} alt="" className="vip-description__img" />
      </div>
      <div className="vip-description__text">
        <h3 className="vip-description__header">{header}</h3>
        <p className="vip-description__body">{body}</p>
      </div>
    </div>
  )
}

export function VIPTiers(props) {
  const {header, body, img} = props;
  return (
    <div className="vip-tiers">
      <h3 className="vip-tiers__header">{header}</h3>
      <p className="vip-tiers__description">{body}</p>
      <img src={img} alt="" className="vip-tiers__img" />
    </div>
  )
}
export function VIPDisplay(props) {
  const {header, body, img, popup} = props;
  return (
    <div className="vip-display">
      <div className="vip-display__img-container">
        <img src={img} alt="" className="vip-display__main-img" />
        <img src={popup} alt="" className="vip-display__popup-img" />
      </div>
      <div className="vip-display__text">
        <h3 className="vip-display__header">{header}</h3>
        <p className="vip-display__description">{body}</p>
      </div>
    </div>
  )
}


// Giveaways
export function GiveawayHero(props) {
  const {heading, img, description} = props;
  return (
    <div className="giveaway-hero">
      <div className="giveaway-hero__overlay"></div>
      <div className="giveaway-hero__text">
        <h1 className="giveaway-hero__heading">{heading}</h1>
        <p className="giveaway-hero__description">{description}</p>
        <a href="/sign-up" className="btn gradient giveaway-hero__cta">SIGN UP</a>
      </div>
      <div className="giveaway-hero__image-wrapper">
        <img src={img} alt="Giveaways" className="giveaway-hero__image" />
      </div>
    </div>
  )
}
export function GiveawaySegments(props) {
  const {img, heading, body} = props;
  return (
    <div className="giveaway-segments">
      <div className="giveaway-segments__text">
        <h2 className="giveaway-segments__heading">{heading}</h2>
        <p className="giveaway-segments__body">{body}</p>
      </div>
      <div className="giveaway-segments__image-wrapper">
        <img src={img} alt="Giveaway Segments" className="giveaway-segments__image" />
      </div>
    </div>
  )
}
export function GiveawayRewards(props) {
  const {heading, body, tImg, bImgOne, bImgTwo} = props;
  return (
    <div className="giveaway-rewards">
      <div className="giveaway-rewards__images">
        <div className="giveaway-rewards__image-row">
          <img src={tImg} alt="Giveaway Stats" className="giveaway-rewards__image" />
        </div>
        <div className="giveaway-rewards__image-row">
          <div className="giveaway-rewards__image-wrapper">
            <img src={bImgOne} alt="Giveaway Coupon" className="giveaway-rewards__split-image" />
          </div>
          <div className="giveaway-rewards__image-wrapper">
            <img src={bImgTwo} alt="Giveaway Points" className="giveaway-rewards__split-image" />
          </div>
        </div>

      </div>
      <div className="giveaway-rewards__text">
        <h2 className="giveaway-rewards__heading">{heading}</h2>
        <p className="giveaway-rewards__body">{body}</p>
      </div>
    </div>
  )
}
export function GiveawayReview(props) {
  const {heading, body, img} = props;
  return (
    <div className="giveaway-review">
      <h2 className="giveaway-review__heading">{heading}</h2>
      <p className="giveaway-review__body">{body}</p>
      <img src={img} alt="Giveaway Review" className="giveaway-review__image" />
    </div>
  )
}

// Promotions
export function PromotionsHero(props) {
  const {heading, description, img} = props;
  return (
    <div className="promotions-hero">
      <div className="promotions-hero__overlay"></div>
      <div className="promotions-hero__text">
        <h1 className="promotions-hero__heading">{heading}</h1>
        <p className="promotions-hero__description">{description}</p>
        <a href="/sign-up" className="btn gradient promotions-hero__cta">SIGN UP</a>
      </div>
      <div className="promotions-hero__img-wrapper">
        <img src={img} alt="Promotions" className="promotions-hero__img" />
      </div>

    </div>
  )
}
export function PromotionsPoints(props) {
  const {heading, body} = props;
  return (
    <div className="promotions-points">
      <div className="promotions-points__text">
        <h2 className="promotions-points__heading">{heading}</h2>
        <p className="promotions-points__body">{body}</p>
      </div>
      <div className="promotions__feature-content">
        <div className="promotions__feature-multiplier">2x</div>
        <div className="promotions__feature-points">points</div>
      </div>
    </div>
  )
}
export function PromotionsAmplifiers(props) {
  const {heading, body, img} = props;
  return (
    <div className="promotions-amplifiers">
      <div className="promotions-amplifiers__img-wrapper">
        <img src={img} alt="Promotions Amplifiers" className="promotions-amplifiers__img" />
      </div>
      <div className="promotions-amplifiers__text">
        <h2 className="promotions-amplifiers__heading">{heading}</h2>
        <p className="promotions-amplifiers__body">{body}</p>
      </div>
    </div>
  )
}
export function PromotionsSchedule(props) {
  const {heading, body, img} = props;
  return (
    <div className="promotions-schedule">
      <div className="promotions-schedule__text">
        <h2 className="promotions-schedule__heading">{heading}</h2>
        <p className="promotions-schedule__body">{body}</p>
      </div>
      <div className="promotions-schedule__img-wrapper">
        <img src={img} alt="Promotions Schedule" className="promotions-schedule__img" />
      </div>
    </div>
  )
}