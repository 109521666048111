import React, { createContext, useState } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export function HandleUser() {
  const [authed, setAuthed]  = useState(() => {
    const storedAuth = JSON.parse(localStorage.getItem('_id'));
    return storedAuth ? storedAuth.accessToken : false;
  });
  
  const getTkn = async (endpoint, setState, field) => {
    axios.get('https://api.amplifyloyalty.com/app/auth/csrf',
      {params: {endpoint}}
    ).then(tkn => {
      if (!field) setState(tkn.data);
      else setState(field, tkn.data, false);
    })
    .catch(err => console.log(err));
  }

  const login = async (values) => {
    // Create a welcome series here to at least authenticate your store
    try {
      const res = await axios.post(
        'https://api.amplifyloyalty.com/app/auth/login',
        values,
        {withCredentials: true}
      );
      if (res.data._id) {
        localStorage.setItem('_id', JSON.stringify({
          _id: res.data._id,
          accessToken: res.data.authToken,
          store: res.data.store,
          stores: res.data.stores,
          name: res.data.name,
          timezone: res.data.timezone
        }));
        setAuthed(res.data.authToken);
        return res.headers;
      } else throw new Error('Server error, please try again later.')
    } catch (e) {
      return Promise.reject(e);
    }
  }
  
  const signup = async (values, shop) => {
    try {
      let res;
      if (shop) {
        res = await axios.post(
          'https://api.amplifyloyalty.com/app/auth/signup',
          {...values, store: shop},
          {withCredentials: true}
        );
      } else {
        res = await axios.post(
          'https://api.amplifyloyalty.com/app/auth/signup',
          values,
          {withCredentials: true}
        );
      }
      localStorage.setItem('_id', JSON.stringify({
        _id: res.data._id,
        accessToken: res.data.authToken,
        name: res.data.name
      }));
      // Need to redo this based on login
      setAuthed(res.data.authToken);
      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  
  const logout = async () => {
    let at = localStorage.getItem('_id');
    try {
      if (at) {
        let res = await axios.post('https://api.amplifyloyalty.com/app/auth/logout',
          {
            token: at
          }
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      localStorage.removeItem('_id');
      setAuthed(false);
      return true;
    }
  }
  
  return ({
    authed,
    login,
    signup,
    logout,
    getTkn
  });
}

export function AuthContextProvider({children}) {
  const authHandler = HandleUser();

  return (
    <AuthContext.Provider value={authHandler}>
      {children}
    </AuthContext.Provider>
  );
};